import {observable} from 'mobx';

// okay | install | installing | installed | failed
export const INSTALL_STATUS = {
    OKAY: "okay",
    INSTALL: "install",
    INSTALLING: "installing",
    INSTALLED: "installed",
    FAILED: "failed",
};

const status = observable.box("");

let dependencies = [];
let message = null, statusMessage = null;
let title = null;

if(window.isRPi)
    dependencies.push(
        {command: 'midori', package: 'midori', installed: false},
    )
else if(window.isLinux)
    dependencies.push(
        {command: 'v4l2-ctl', package: 'v4l-utils', installed: false},
        {command: 'remmina', package: 'remmina', installed: false}
    );

const checkDependencies = async() => {
    dependencies = (await window.api.checkDependencies(dependencies))
                        .filter(d => !d.installed);
    console.log("dependencies", dependencies);

    // create title
    if(!title) 
        title = window.isRPi ? `Please install required dependencies` :
        `V2 Cloud app requires ${[dependencies.map(d => d.package).join(", ")]} to unlock all features.`;

    // update status
    const previousStatus = status.get();
    if(previousStatus == INSTALL_STATUS.INSTALLING) {
        message = dependencies.length ? title : null;
        statusMessage = dependencies.length ? "Installation failed! Do you want to retry?" : "Installation completed!";
        status.set([dependencies.length ? INSTALL_STATUS.FAILED : INSTALL_STATUS.INSTALLED]);
    } else {
        message = dependencies.length ? title : null;
        statusMessage = dependencies.length ? "Do you want to install?" : null
        status.set([dependencies.length ? INSTALL_STATUS.INSTALL : INSTALL_STATUS.OKAY]);
    }

    if(!previousStatus && status.get() == INSTALL_STATUS.INSTALL){
        if(window.isRPi) {
            await window.api.poll(async() => await window.api.have_internet(),
                100000000, 3000); // timeout, interval
            installDependencies() // auto install
        }
        // open install modal
        window.$('#install-dependencies-modal').modal({
            backdrop: 'static'
        })
    }
}

const installDependencies = async () => {
    statusMessage = "Installing ...";
    status.set(INSTALL_STATUS.INSTALLING);
    await window.api.install(dependencies.map(d => d.package));
    await checkDependencies();
}

export const installDependenciesController = {
    visible: window.isLinux,
    get status() {return status.get()},
    checkDependencies,
    installDependencies,
    getDependencies: () => dependencies.map(d => d.package),
    get message() {return  message },
    get statusMessage() { return statusMessage }
};
