import React from 'react';
import axios from 'axios';
import {observable} from 'mobx';
import {observer} from 'mobx-react';

import {fetch_permission, is_ad_user, ad_user_id, permission_list} from '../userData.js';
import {freeRDPController} from '../rpiClient/FreeRDPController';
import { cameraController } from '../CameraController';
import { settingsController } from '../SettingsController';
import { poll_task } from '../connectUtils.js';


const connection_steps = observable.box([], {deep: false});


const add_connection_step = text => {

  connection_steps.set([
    ...connection_steps.get(),
    [text, 'loading']
  ]);

};

const change_connection_step = status => {

  connection_steps.set([
    ...connection_steps.get().slice(0, -1),
    [connection_steps.get().slice(-1)[0][0], status]
  ]);

};


export const RDPModal = observer(() => (

  <div className="modal fade" id="use-rdp" tabIndex="-1" role="dialog" aria-labelledby="use-rdp" aria-hidden="true">
     <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
           <div className="modal-header">
              <h5 className="modal-title inner-modal-dashboard">
                 <i className="fa fa-television" aria-hidden="true" style={{paddingRight: '5px'}}></i>Use RDP
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div className="modal-body">
              <div className="the-content-inner text-dark">
                 <div className="row ">
                    <div className="col-md-12">

                      { window.isElectron ?

                          <ul style={{listStyle: 'none'}}>

                          { connection_steps.get().map(([text, status]) =>

                            <li style={{padding: '10px'}} key={text + status}>
                              {
                                status === 'pass' &&
                                  <i className="fa fa-check-circle" style={{color: 'green', marginRight: '10px', fontSize: '1.25em', verticalAlign: 'middle'}}></i>
                              }

                              {
                                status === 'fail' &&
                                  <i className="fa fa-exclamation-triangle" style={{color: 'red', marginRight: '10px', fontSize: '1.25em', verticalAlign: 'middle'}}></i>
                              }

                              {
                                status === 'loading' &&
                                  <i className="fa fa-spinner fa-pulse" style={{color: '#AAAAAA', marginRight: '15px'}}></i>
                              }

                              {text}
                            </li>

                          )}

                          </ul>

                        :
                          <p>
                            RDP is only available in the desktop version.&nbsp;&nbsp;
                            { /* <button style={{
                              background: '#00438b',
                              color: 'white',
                              border: 'none',
                              borderRadius: '3px',
                              padding: '7px'
                            }}
                              onClick={() => {}}><i
                              className="fa fa-desktop"
                              aria-hidden="true" style={{verticalAlign: 'middle'}}></i> Open in Desktop</button> */ }
                          </p>
                      }
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>

));



export const open_rdp = permId => {

  if(window.$('#use-rdp').is(':visible')) {
    console.log("Aborting open_rdp(). Modal still visible.")

    return
  }

  window.$('#use-rdp').modal('show');

  if(window.isRPi) {

    connection_steps.set([]);
    freeRDPController.openRDP(permId,
      // stepUpdater
      { add_connection_step, change_connection_step });

  } else if(window.isElectron) {

    _open_rdp(permId);

  }

}

const _open_rdp = async permId => {

  connection_steps.set([]);

  add_connection_step('Fetching V2 connection configuration');


  let rdp_params;
  let task_id;

  const pl = permission_list.get();
  const perm = pl[permId];

  try {

    if(is_ad_user.get()) {

      let q;

      if(perm.type == 'vmpool' || perm.type == 'vmpool-app') {
        q = `/api/advmpoolaccesss/${perm.id}/ssh_rdp_params`;
      } else {
        q = `/api/advmaccesss/${perm.id}/ssh_rdp_params`;
      }

      const data = (await axios({
        url: q,
        params: {
          ...(pl[permId].app && { app: pl[permId].app.id })
        }
      })).data;

      rdp_params = data;
      task_id = data.task && data.task.id;

      perm.last_vmpool_vm_id = data.vm;
      perm.last_vmpool_vmuser_id = data.vmuser;
      permission_list.set(pl);

    } else {

      const data = (await axios({
        url: `/api/permissions/${permId}/ssh_rdp_params`,
        params: {
        }
      })).data;

      rdp_params = data;
      task_id = data.task && data.task.id;

      perm.last_vmpool_vm_id = data.vm;
      perm.last_vmpool_vmuser_id = data.vmuser;
      permission_list.set(pl);

    }

  } catch(e) {

    change_connection_step('fail');
    throw e;

  }

  change_connection_step('pass');


  if(task_id) {

    add_connection_step('Creating VM pool system user');

    try {

      await poll_task(task_id);
      change_connection_step('pass');

    } catch(e) {

      change_connection_step('fail');
      throw e;

    }

  }


  // setup video stream if needed
  if(settingsController.getSettings().redirectCamera){

    add_connection_step('Setting up webcam redirection (may take up to 1 minute)');
    let isCameraStreaming = null;
    try {
      let tk;
      if(ad_user_id.get()) {
        tk = `${perm.vm.id}_${ad_user_id.get()}`;
      } else {
        tk = rdp_params.vmuser || (perm.vmuser && perm.vmuser.id);
      }

      await cameraController.controlByContext({...rdp_params,
        tunnelKey: tk,
        description: perm.vm.descriptor, vm_id: perm.vm.id});

      // check camera stream is working
      isCameraStreaming = await new Promise(resolve => setTimeout(() => resolve(cameraController.isStreaming), 2000));

    } catch(e) {

      change_connection_step('fail');

      throw e;

    }

    change_connection_step(isCameraStreaming ? 'pass' : 'fail');

  }


  try {

    await window.api.open_rdp({
      ...rdp_params,

      add_connection_step,
      change_connection_step,

      useMultimon: settingsController.getSettings().useMultimon,
      useMultimonSelection: settingsController.getSettings().useMultimonSelection,
      useNetworkIP: settingsController.getSettings().useNetworkIP,
      multimonSelected: settingsController.getSettings().multimonSelected
    });

  } catch(e) {

    console.error(e);

  }


  setTimeout(() => window.$('#use-rdp').modal('hide'), 500);

};
