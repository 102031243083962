import React from 'react';
import { observer } from 'mobx-react';

export const CameraSelection = observer(({controller}) => {
  
  return (
  <div className="ml-3">
    <div className="row">
    {/* DEVICES */}
      <div className="col-11">
        {
			controller.status === 'scanning'
			? <h6 className="mt-2">{controller.message}</h6>
			: controller.availableDevices.length > 0
			  ? <div className="input-group">
				  <select id="camera-devices" className="form-control border-secondary"
					value={controller.selectedDevice.id}
					onChange={e => controller.updateSelectedDevice(e.target.value)}
					disabled={controller.isStreaming}
          style={{ WebkitUserSelect: "none", cursor: "pointer" }}
					>
					{controller.availableDevices.map((device) =>
					  <option key={device.id} value={device.id}>{device.name}</option>
					)}
				  </select>
          { controller.isStreaming && 
          <div className="input-group-append">
            <button type="button" className="btn border-secondary" style={{width: "50px"}}
              onClick={ () => controller.stopCamera()} >
              <i className="fa fa-stop"  style={{color: "red"}} aria-hidden="true"></i>
            </button>
          </div>
          }
				</div>
			  : <h6 className="mt-2 text-danger">No devices found. Please try again!</h6>
		  }
      </div>

      { !controller.isStreaming &&
        <div className="col col-1 p-0">
        { controller.status === 'scanning'
          ? <span className="spinner-border ml-2" role="status" aria-hidden="true" style={{color:"#00438b"}}></span>
          : <button type="button" className="btn pl-2" onClick={controller.scan} style={{fontSize:"0.8rem"}}>
              <i className="fa fa-refresh fa-2x" style={{color:"#00438b"}} aria-hidden="true"></i>
            </button>
        }
        </div>
      }
    </div>
  </div>
  );
});
