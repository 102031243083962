import React from 'react';
import { observer } from 'mobx-react';


export const AudioSettings = observer(({controller}) => {
  
  return (
  <div className="ml-3">
    <div className="row">
    {/* DEVICES */}
      <div className="col-11">
        {
          controller.status === 'scanning'
          ? <h6 className="mt-2">{controller.message}</h6>
          : controller.availableDevices.length > 0
            ? <select id={`${controller.type}devices`} className="form-control border-secondary"
            value={controller.selectedDevice.id}
            onChange={e => controller.updateSelectedDevice(e.target.value)}
            >
                {controller.availableDevices.map((device) => 
                  <option key={device.id} value={device.id}>{device.name}</option>
                )}
              </select>
            : <h6 className="mt-2 text-danger">No devices found. Please try again!</h6>
        }
      </div>

      <div className="col col-1 p-0">
      { controller.status === 'scanning'
        ? <span className="spinner-border ml-2" role="status" aria-hidden="true" style={{color:"#00438b"}}></span>
        : <button type="button" className="btn pl-2" onClick={controller.scan} style={{fontSize:"0.8rem"}}>
            <i className="fa fa-refresh fa-2x" style={{color:"#00438b"}} aria-hidden="true"></i>
          </button>
      }
      </div>
    </div>
    {/* VOLUME */}
    { controller.selectedDevice &&
      <div className="row pt-1">
        <div className="col col-1">
          <i className={controller.iconStatus} style={{color:"#00438b"}} aria-hidden="true"></i>
        </div>
        <div className="col col-10">
          <input type="range" className="custom-range pt-1" min="0" max="100" step="1" id="soundVolume" value={controller.selectedDevice.volume}
          onChange={(e) => controller.setVolume(Number(e.target.value))}
          ></input>
        </div>
        <div className="col col-1 p-0">
          <h5 className="text-center"  style={{color:"black"}}>{controller.selectedDevice.volume}</h5>
        </div>
      </div>
    }
  </div>
  );
});
