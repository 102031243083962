import {autorun} from 'mobx';
import {app_settings, settingsController} from './SettingsController.js';
import {open_rdp} from './components/RDPModal.js';
import {active_perm} from './activeVM.js';
import {discard_active_state} from './hashState.js';
import {debounce} from 'lodash';


const update_useRDP = () => {

  if(app_settings.get().useRDP) {

    setTimeout(() => {

      const perm = active_perm.get();

      discard_active_state();

      perm && open_rdp(perm);

    }, 0);

  }

};

autorun(update_useRDP);


export const open_vm = debounce(perm_id => {

  if(app_settings.get().useRDP && window.isElectron) {

    open_rdp(String(perm_id));

  } else {

    active_perm.set(String(perm_id));

  }

}, 1000, {
  leading: true
});
