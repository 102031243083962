import React from 'react';
import {observable} from 'mobx';
import {observer} from 'mobx-react';

import copyToClipboard from '../util/clipboard.js';

import {active_vm_frame} from '../activeVM.js';



class ShareButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {showSpinner: false, showCheck: false};
  }


  async click() {

    if(this.state.showSpinner) return;

    this.setState({
      showSpinner: true,
      showCheck: false
    });

    const share_link = await active_vm_frame.get().share_link(this.props.isWithControl);

    this.setState({
      showSpinner: false,
      showCheck: true
    });


    // copyToClipboard(window.location.origin + '#share/' + share_link + '&v2mobileclient');
    copyToClipboard(share_link);


    // wait 2000ms
    await new Promise(r => setTimeout(r, 2000));

    this.setState({
      showSpinner: false,
      showCheck: false
    });

  }

  render() {

    return (

      <>

        <div className="col-md-1 align-self-center">

          { this.state.showSpinner &&
            <div className="spinner-border" role="status" style={{color: '#00438b'}}>
              <span className="sr-only">Loading...</span>
            </div>
          }

          { this.state.showCheck &&
            <i className="fa fa-check" aria-hidden="true" style={{color: '#00438b', fontSize: '1.5em'}}></i>
          }

        </div>
        <div className="col-md-2 align-self-center">
           <div className="text-right">
              <button className="btn btn-primary" onClick={() => this.click()}>Copy Link</button>
           </div>
        </div>

      </>

    );

  }

}


export const ShareScreenModal = observer(() =>

  <div className="modal fade" id="share-screen-popup" tabIndex="-1" role="dialog" aria-labelledby="share-screen-popupLabel" aria-hidden="true">
     <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
           <div className="modal-header">
              <h5 className="modal-title inner-modal-dashboard"><i className="fa fa-share-alt" aria-hidden="true"></i> Share View</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div className="modal-body">
              <div className="the-content-inner text-dark">
                 <h3>View Only <i className="fa fa-eye" aria-hidden="true"></i></h3>
                 <div className="row ">
                    <div className="col-md-9">
                       <p>The people you're sharing this link with will be able to view your screen on their browser but won't be able to take control.</p>
                    </div>
                    <ShareButton isWithControl={true}/>
                 </div>
                 <h3>With Control <i className="fa fa-eye" aria-hidden="true"></i><i className="fa fa-keyboard-o" aria-hidden="true"></i><i className="fa fa-hand-pointer-o" aria-hidden="true"></i></h3>
                 <div className="row ">
                    <div className="col-md-9">
                       <p>The people you're sharing this link with will be able to view your screen on their browser as well as control the mouse and keyboard.</p>
                    </div>
                    <ShareButton isWithControl={false}/>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>

);
//
//     <div className="modal fade" id="share-screen-popup" tabIndex="-1" role="dialog" aria-labelledby="share-screen-popupLabel" aria-hidden="true">
//         <div className="modal-dialog modal-dialog-centered modal-lg custom-modal-inner" role="document">
//             <div className="modal-content">
//                 <div className="modal-header">
//                     <h5 className="modal-title inner-modal-dashboard"><i className="fa fa-share-alt-square" aria-hidden="true"></i> Share View</h5>
//                     <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                         <span aria-hidden="true">&times;</span>
//                     </button>
//                 </div>
//                 <div className="modal-body">
//
//                     <div className="the-content-inner text-dark">
//                         <h3>View Only</h3>
//                         <div className="row ">
//                             <div className="col-md-10">
//                                 <p>The people you're sharing this link with will be able to view your screen on their browser but won't be able to take control.</p>
//                             </div>
//                             <div className="col-md-2 align-self-center">
//                                 <div className="text-right">
//                                     <button className="btn btn-primary"
//                                        onClick={() => share(true)}>
//                                        Get Link
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//                         <h3>With Control</h3>
//                         <div className="row ">
//                             <div className="col-md-10">
//                                 <p>The people you're sharing this link with will be able to view your screen on their browser as well as control the mouse and keyboard.</p>
//                             </div>
//                             <div className="col-md-2 align-self-center">
//                                 <div className="text-right">
//                                     <button className="btn btn-primary"
//                                        onClick={() => share(false)}>
//                                        Get Link
//                                     </button>
//                                 </div>
//                             </div>
//                         </div>
//
//                         <p>Link: {link.get() || 'none'}</p>
//                         <button
//                             className="btn btn-primary"
//                             onClick={() => link.get() && copyToClipboard(link.get())}>
//                             Copy Link</button>
//
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
//
// );
