import React from 'react';

import {observer} from 'mobx-react';
import {active_perm, active_vm_src_override} from  '../activeVM.js';

import {WithLoadingScreen} from './LoadingScreen.js';
import {WebconnectFrame, BareFrame} from './util/WebconnectFrame.js';
import Splash from './Splash.js';


export default observer(() =>

  <>

    {/* The WebconnectFrame must remain appended to the DOM to maintain iframe persistence
    across deselection. */}

    <div style={{
      width: '100%',
      height: '100%',
      display: active_perm.get() ? 'block' : 'none'
    }}>

      <WebconnectFrame
        cache_key={'perm_id'}
        perm_id={active_perm.get()}
        style={{
          border: 0,
          width: '100%',
          height: '100%'
        }}
        />

    </div>


    { !active_perm.get() &&

      (active_vm_src_override.get() ?

        <BareFrame
          src={active_vm_src_override.get()}
          style={{
            border: 0,
            width: '100%',
            height: '100%'
          }}
          />

      :

        <WithLoadingScreen>
          <Splash/>
        </WithLoadingScreen>

    )}

  </>

);
