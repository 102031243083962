import axios from 'axios';
import { action, observable, decorate } from 'mobx';
import { printerController } from './PrinterController.js';
import { cameraController } from '../CameraController.js';
import { microphoneController } from './AudioController.js';
import { settingsController } from '../SettingsController';
import { fetch_permission, ad_user_id, is_ad_user, permission_list } from '../userData.js';
import { poll_task } from '../connectUtils.js';

class FreeRDPController {

  status = 'loading';
  message = '';

  printerController;
  cameraController;
  microphoneController;

  constructor(printerController, cameraController, microphoneController) {
    this.printerController = printerController;
    this.cameraController = cameraController;
    this.microphoneController = microphoneController;
  }

  //@action
  async openRDP(permId, stepUpdater) {

    window.$('#use-rdp').modal('show');

    this.status = 'loading';
    this.message = 'Openning RDP application ...';

    stepUpdater.add_connection_step('Fetching V2 connection configuration');

    const current_rpi_settings = settingsController.getSettings();

    let rdp_params, task_id;

    const pl = permission_list.get();
    const perm = pl[permId];

    try {

      if(is_ad_user.get()) {

        let q;

        if(perm.type == 'vmpool' || perm.type == 'vmpool-app') {
          q = `/api/advmpoolaccesss/${perm.id}/ssh_rdp_params`;
        } else {
          q = `/api/advmaccesss/${perm.id}/ssh_rdp_params`;
        }

        const data = (await axios({
          url: q,
          params: {
            ...(pl[permId].app && { app: pl[permId].app.id })
          }
        })).data;

        rdp_params = data;
        task_id = data.task && data.task.id;

        perm.last_vmpool_vm_id = data.vm;
        perm.last_vmpool_vmuser_id = data.vmuser;
        permission_list.set(pl);

      } else {

        const data = (await axios({
          url: `/api/permissions/${permId}/ssh_rdp_params`,
          params: {
          }
        })).data;

        rdp_params = data;
        task_id = data.task && data.task.id;

        perm.last_vmpool_vm_id = data.vm;
        perm.last_vmpool_vmuser_id = data.vmuser;
        permission_list.set(pl);
      }


    } catch(e) {

      stepUpdater.change_connection_step('fail');

      throw e;

    }

    stepUpdater.change_connection_step('pass');


    if(task_id) {

      stepUpdater.add_connection_step('Creating VM pool system user');

      try {

        await poll_task(task_id);
        stepUpdater.change_connection_step('pass');

      } catch(e) {

        stepUpdater.change_connection_step('fail');
        throw e;

      }

    }


    const {
      rdp_port,
      private_ip,
      ssh_port,
      ssh_username,
      public_dns,
      ssh_user_password,
      os_username,
      os_domain,
      os_password,
      executable_path,
      type,
      name,
      description,
    } = rdp_params;

    try {

      const requires = {
        os_username,
        os_domain,
        os_password,
        public_dns,
        ssh_port,
        ssh_username,
        ssh_user_password,
        private_ip,
        rdp_port,
        executable_path,
        type,
      };

      // fake check speaker to let user know they selected sound redirection
      if(current_rpi_settings.redirectSound){
        stepUpdater.add_connection_step('Checking speaker before redirection');
        stepUpdater.change_connection_step('pass');
      }

      // check microphone
      let isMicrophoneReady;
      if(current_rpi_settings.redirectMicrophone){
        stepUpdater.add_connection_step('Checking microphone before redirection');
        // skip check microphone in new RPi OS
        window.api.isNewOS || await this.microphoneController.scan();// rescan and try to select available microphone
        isMicrophoneReady = window.api.isNewOS || !!this.microphoneController.selectedDevice;
        isMicrophoneReady ? stepUpdater.change_connection_step('pass')
                              : stepUpdater.change_connection_step('fail');
      }

      // setup video stream if needed
      if(current_rpi_settings.redirectCamera){
        stepUpdater.add_connection_step('Checking webcam before redirection');
        try {
          // start camera redirection LONG process
          await cameraController.controlByContext({public_dns, ssh_port, ssh_user_password, ssh_username,
            tunnelKey: ad_user_id.get() ? `${perm.vm.id}_${ad_user_id.get()}` : perm.vmuser.id,
            description: perm.vm.descriptor, vm_id: perm.vm.id});
        } catch(e) {
          stepUpdater.change_connection_step('fail');
        }
        stepUpdater.change_connection_step(cameraController.isStreaming ? 'pass' : 'fail');
        cameraController.isStreaming && window.api.set_RDP_is_starting(true) // disable isStreaming api to wait for starting freerdp 
      }

      // rescan available printer to eliminate unavailable printers in saved printers
      (current_rpi_settings.printers.length > 0) && await printerController.scan();


      stepUpdater.add_connection_step('Establishing secure connection');
      // build options
      const options =  {
        screen: current_rpi_settings.useMultimon
                  ? '/multimon:force'
                  : '/f',
        sound: current_rpi_settings.redirectSound
                  ? `/sound:${window.api.isNewOS ? '' : 'sys:alsa,format:1,'}quality:medium,latency:200`
                  : '' ,
        mic: current_rpi_settings.redirectMicrophone && isMicrophoneReady
                  ? `/microphone${window.api.isNewOS ? '' : ':sys:alsa,format:1'}`
                  : '',
        printers: current_rpi_settings.redirectAllPrinters
                  ? '/printer'
                  : printerController.selectedList.length > 0
                    ? '/printer:' + printerController.selectedList.reduce(
                      (acc, cur) => acc += ' /printer:' + cur + " "
                    )
                    : '',
        predefined: `/network:auto /compression /gfx:rfx /gdi:hw /video /cert:ignore ${!!os_password ? "" : "/sec:rdp"}`
      };

      await window.api.openFreeRDP({ requires, options});
      this.status = 'success';
      stepUpdater.change_connection_step('pass');

      // wait few seconds for rdp open
      stepUpdater.add_connection_step('Launching RDP');
      await new Promise(resolve => setTimeout(resolve, 2000));
      stepUpdater.change_connection_step('pass');

      window.$('#use-rdp').modal('hide');
      window.api.set_RDP_is_starting(false) // enable isStreaming api

    } catch (error) {
      this.status = 'failed';
      console.log(error);
      stepUpdater.change_connection_step('fail');
    }
  }
}

decorate(FreeRDPController, {
  status: observable,
  message: observable,
  openRDP: action,
});

export const freeRDPController = new FreeRDPController(printerController, cameraController, microphoneController);
