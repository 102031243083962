import React from 'react';
import { observer } from 'mobx-react';

const MIN_WIDTH = 999;

export const DisplayModal = observer(({controller}) => {
  //~ console.log(controller);
  controller.isFirstLoad = true;

  return (
    <div className="modal fade" id="setup-display" tabIndex="-1" role="dialog" aria-labelledby="setup-display" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title inner-modal-dashboard">
              <i className="fa fa-television" aria-hidden="true" style={{ paddingRight: '5px' }}></i> {controller.title}
            </h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-3">

            {/* ILLUSTRATION */}
            <div className="d-flex justify-content-center mb-3" style={{ height: `${controller.illustrationHeight}px`, background: '#bbb' }}>
              <div className={controller.displayHDMI1.positionOptions 
                ? "d-flex justify-content-center align-self-center " + controller.displayHDMI1.selectedOption.valueCSS
                : "d-flex justify-content-center align-self-center"} >
              
              {controller.status !== "loading" && controller.displayHDMI0.isAvailable && !!controller.displayHDMI0.selectedMode &&
                <div className="p-2 d-flex justify-content-center card" 
                  style={{ width: `${controller.displayHDMI0.displayWidth}px`, height: `${controller.displayHDMI0.displayHeight}px`, background: "#004FA4" }}>
                  <h5 className="align-self-center text-center" style={{ color: 'white' }}>{controller.displayHDMI0.displayInfo.name}</h5>
                  <h6 className="align-self-center text-center" style={{ color: 'white' }}>
                    {`${controller.displayHDMI0.selectedMode.width}x${controller.displayHDMI0.selectedMode.height}`}</h6>
                </div>
              }
              
              {controller.status !== "loading" && controller.displayHDMI1.isAvailable && !!controller.displayHDMI1.selectedMode &&
                <div className="p-2 d-flex justify-content-center card" 
                  style={{ width: `${controller.displayHDMI1.displayWidth}px`, height: `${controller.displayHDMI1.displayHeight}px`, background: "#0076F4" }}>
                  <h5 className="align-self-center text-center" style={{ color: 'white' }}>{controller.displayHDMI1.displayInfo.name}</h5>
                  <h6 className="align-self-center text-center" style={{ color: 'white' }}>
                    {`${controller.displayHDMI1.selectedMode.width}x${controller.displayHDMI1.selectedMode.height}`}</h6>
                </div>
              }
              </div>
            </div>

            {/* SETTINGS */}
            <div className="row d-flex justify-content-center">
              {/* HDMI0 */}
              { controller.status !== "loading" && controller.displayHDMI0.isAvailable && 
                <DisplaySettings display={controller.displayHDMI0} isAutoResolution={controller.isAutoResolution} />
              }
              {/* HDMI1 */}
              { controller.status !== "loading" && controller.displayHDMI1.isAvailable && 
                <DisplaySettings display={controller.displayHDMI1} isAutoResolution={controller.isAutoResolution} />
              }
              {/* Auto Resolution */}
              <div className="col col-12 align-self-start">
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="checkbox" id="autoResolution" value="autoResolution"
                  checked={!!controller.isAutoResolution}
                  onChange={e => controller.updateAutoResolution(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="autoResolution" style={{ color: "#00438b", WebkitUserSelect: "none", cursor: "pointer" }}>Auto detect and set native resolution for attached monitors</label>
                </div>
              </div>
              {/* Note */}
              { !controller.isAutoResolution &&
                <div className="col col-12 align-self-start">
                  <p className="mb-0">If you choose other resolutions, you may need to turn on/off your monitors after rebooting</p>
                </div>
              }
              <div className="col col-12 align-self-start pt-1">
                <p className="mb-0 text-dark">Only HDMI0 port can support high resolution monitor up to 4K</p>
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex">
            <span className="flex-grow-1 text-danger">System will auto reboot to apply changes</span>
            <button type="button" className="btn btn-primary py-2" onClick={controller.applyChanges}>Apply changes</button>
          </div>
        </div>
      </div>
    </div>
  );
});

const DisplaySettings = observer(({display, isAutoResolution}) => {
  
  return (
  <div className="col col-6 pt-2 align-self-start">
    <h4 className="" style={{ fontWeight: "bold" }}>{display.displayInfo.name}</h4>
    {/* Recommended Modes */}
    <div className="row flex-md-row">
      <div className="col col-3 pt-2">
        <h6 className="" style={{ color: "#00438b" }}>Resolution</h6>
      </div>
      <div className="form-group col-md-9">
        <select id="hdmi0-modes" className="form-control border-secondary"
          value={display.selectedMode.id}
          onChange={e => display.updateSelectedMode(e.target.value)}
          disabled={isAutoResolution ? "disabled" : null}
          style={{ WebkitUserSelect: "none", cursor: "pointer" }}
        >
          {display.displayInfo.modes.map((mode) => 
              <option key={mode.id} value={mode.id}
                style={{display: mode.width > MIN_WIDTH ? "block" : "none"}}
              >
                {`${mode.width}x${mode.height} @ ${mode.rate}Hz ${mode.group}`}
              </option>
            )}
        </select>
      </div>
    </div>
    
    { display.positionOptions &&
    <div className="row">
      <div className="col col-3 pt-2">
        <h6 className="" style={{ color: "#00438b" }}>Position</h6>
      </div>
      <div className="form-group col-md-9">
        <select id="hdmi0" className="form-control border-secondary"
          value={display.selectedOption.id}
          onChange={e => display.updateSelectedOption(e.target.value)}
          style={{ WebkitUserSelect: "none", cursor: "pointer" }}
        >
          {display.positionOptions.map((position) => 
              <option key={position.id} value={position.id}>{position.name}</option>
            )}
        </select>
      </div>
    </div>
    }
  </div> 
  );
});

