import {observable, autorun} from 'mobx';


export const is_loading = observable.box(false);

const loading_stack = observable.box(0);

autorun(() => {

  if(loading_stack.get() === 0) {
    is_loading.set(false);
  } else {
    is_loading.set(true);
  }
});


const push = () => loading_stack.set(loading_stack.get() + 1);

// prevent screen flicker if resolution of observer triggers another loading screen
const pop = () => setTimeout(() => loading_stack.set(loading_stack.get() - 1), 0);


// Wrap the asynchronous function f with a loading screen

export const show_loading_screen =

  f => (...args) => new Promise((resolve, reject) => {


  push();

  f(...args).then(
    v => {
      pop();
      resolve(v);
    },
    v => {
      pop();
      reject(v);
    }
  );

});
