import axios from 'axios';
import {ad_user_id, is_ad_user, permission_list} from './userData.js';


export const signoff = async perm_id => {

  const perm = permission_list.get()[perm_id];
  if(!perm) return;

  if(perm.type == 'vmpool' || perm.type == 'vmpool-app') {

    if(is_ad_user.get() && perm.last_vmpool_vm_id) {

      await signoff_vm(perm.last_vmpool_vm_id);

    } else if(!is_ad_user.get() && perm.last_vmpool_vmuser_id) {

      await signoff_vmuser(perm.last_vmpool_vmuser_id);

    } else {

      window.alert("Can't sign off until connection has first been established.");

    }

  } else {

    if(is_ad_user.get()) {

      await signoff_vm(perm.vm.id);

    } else if(perm.vmuser) {

      await signoff_vmuser(perm.vmuser.id);

    } else {}
  }
}

export const signoff_vmuser = async vmuser_id => {

  await axios({
    method: 'post',
    timeout: 200000,
    url: `/api/vmusers/${vmuser_id}/sign_out_vmuser/`
  });

};

export const signoff_vm = async vm_id => {

  const bodyFormData = new FormData();
  bodyFormData.append('vm', vm_id);

  await axios({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    method: 'post',
    timeout: 200000,
    url: `/api/adusers/${ad_user_id.get()}/sign_out_from_vm/`,
    data: bodyFormData
  });

};
