import { reaction, toJS } from 'mobx';
import { cameraController } from './CameraController.js';
import { printerController } from './rpiClient/PrinterController.js';
import { persistent_observable } from './storage';


let SETTINGS = {
  useRDP: window.isElectron,
  useMultimon: true,
  useMultimonSelection: false,
  useNetworkIP: false,
  redirectCamera: false,
  camera: cameraController.NULL_DEVICE,
  multimonSelected: []
};

if(window.isRPi) {

  SETTINGS = {
    ...SETTINGS,
    redirectSound: true,
    redirectMicrophone: false,
    redirectAllPrinters: true,
    printers: [],
  }

}


export const app_settings = persistent_observable('app_settings', SETTINGS);

const toggleOptionByValue = key => {

  const current_app_settings = app_settings.get();


  // check key is available and has boolean type
  // if(typeof current_app_settings[key] !== "boolean"){
  //   console.error("toggleOptionByValue: key is invalid!")
  //   return;
  // }

  current_app_settings[key] = !current_app_settings[key];

  app_settings.set({...current_app_settings});
}


const setOptionByValue = (key, value) => {

  const current_app_settings = app_settings.get();

  current_app_settings[key] = value;

  app_settings.set({...current_app_settings});

}


// save user settings reaction START
reaction(
  () => cameraController.selectedDevice,
  selectedDevice => {
    // console.log(toJS(selectedDevice));
    const current_app_settings = app_settings.get();

    app_settings.set({
      ...current_app_settings,
      camera: toJS(selectedDevice)
    });
  },
);

if(window.isRPi) {
  reaction(
    () => printerController.selectedList,
    selectedList => {
      // console.log(toJS(selectedDevice));
      const current_app_settings = app_settings.get();

      app_settings.set({
        ...current_app_settings,
        printers: toJS(selectedList)
      });
    },
  );
}
// save settings reaction END

export const settingsController = {
  toggleOptionByValue,
  setOptionByValue,
  getSettings: () => app_settings.get(),
};

// sync settings 1st time
if(settingsController.getSettings().camera) {
  console.log("Sync preferred camera from user settings");
  cameraController.syncSelectedDeviceFromLocalStorage(settingsController.getSettings().camera);
}

if(settingsController.getSettings().printers){
  console.log("Sync preferred printers from user settings");
  printerController.syncSelectedListFromLocalStorage(settingsController.getSettings().printers);
}
