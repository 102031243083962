import React from 'react';
import axios from 'axios';

import * as auth from '../../auth.js';

import {action} from 'mobx';
import {observer} from 'mobx-react';

import {fetch_user_permissions} from '../../userData.js';
import {active_perm} from '../../activeVM.js';
// import {vm_cache} from '../util/WebconnectFrame.js';

import {freeRDPController} from '../../rpiClient/FreeRDPController.js';
import { settingsController } from '../../SettingsController';
import {VMPeekaboo} from './VMPeekaboo.js';
import { open_vm } from '../../useRDP.js';
import {signoff_vmuser} from '../../signoff.js';


function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}



export const VMUser = class extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      collapsed: true,
      loading: false,
    };

  }


  async _signoff() {

    if(!window.confirm('Are you sure you want to sign off this VM user?')) return;

    this.setState({loading: true});

    try {
      await signoff_vmuser(this.props.vmuser_id);
    } catch(e) {
      console.error(e);
    }

    this.setState({loading: false});

  }



  async click() {

    if(this.state.collapsed) {

      this.setState({loading: true})

      const permissions = await fetch_user_permissions(this.props.vmuser_id, this.props.vm_id);


      // if only one permission, then open it directly

      if(permissions.length === 1) {

        this.setState({loading: false});

        open_vm(permissions[0].id);

      // open the peekaboo menu

      } else {

        this.setState({
          collapsed: false,
          loading: false,
        });

      }

    } else {

      this.setState({
        collapsed: true,
        loading: false,
      });

    }

  }


  render() {


    const {vm_id, vmuser_id, user_id, username} = this.props;

    const is_selected = false;
    const in_cache = false;

    return (

      <li>

        <div className="status-bar" id="status-bar">
            {/* <i onClick={() => refresh(vm_user_string)} className="fa fa-refresh refresh-button status-icon-bg-hover-only" aria-hidden="true" title="Refresh"></i> */ }
            <i onClick={() => this._signoff()} className="fa fa-sign-out refresh-button status-icon-bg-hover-only" aria-hidden="true" title="Sign off" style={{marginTop: '7px'}}></i>
        </div>

        <a href="javascript:void(0);"
          className={is_selected ? "active" : (in_cache ? "available" : '')}
          onClick={() => this.click()}
          >

          <i className="fa fa-spinner fa-pulse" style={{marginLeft: '-7px', marginRight: '7px', visibility: this.state.loading ? 'visible' : 'hidden'}}></i>
          {username}
        </a>

        { !this.state.collapsed &&

          <VMPeekaboo
            vmuser_id={vmuser_id}
            destroy={() => this.setState({collapsed: true})}/>

        }

      </li>

    );

  }

};



// const refresh = vm_user_string => {
//
//   action(() => {
//     active_vm.set()
//     active_user.set()
//   })();
//
//   vm_cache.delete(vm_user_string);
//
//
//   // Force observer update, then select the vm
//
//   window.setTimeout(select, 0);
//
// };
