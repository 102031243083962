import {action, computed, observable, decorate, toJS, observe, autorun, reaction} from 'mobx';
import {active_perm} from './activeVM.js';
import {vms_list, permission_list, is_ad_user, ad_user_id} from './userData.js';
import axios from 'axios';
import poll from './util/poll';
import { vm_cache } from './components/util/WebconnectFrame.js';

const PROXY_PRIVATE_IP = '169.254.1.1';

class CameraController {

  firstLoad = true;// autoload 1st time
  title = "Choose your preferred camera";
  checkStreamStatus = null;

  NULL_DEVICE = {"id": -1, "name": "Choose your preferred camera"};

  static STREAM_STATUS = {
    NO_STREAM: 'no_stream',
    STOPPING_STREAM: 'stopping_stream',
    STARTING_STREAM: 'starting_stream',
    STREAMMING: 'streamming',
  }

  static TUNNEL_STATUS = {
    NO_TUNNEL: 'no_tunnel',
    STOPPING_TUNNEL: 'stopping_tunnel',
    STARTING_TUNNEL: 'starting_tunnel',
    TUNNELING: 'tunneling',
  }

  //@observable
  message = '';
  activeVM = null;

  status = 'done';
  availableDevices = [];
  selectedDevice = this.NULL_DEVICE;
  tunnels = {};
  streamStatus = CameraController.STREAM_STATUS.NO_STREAM;
  tunnelsStatus = {}; // status of tunnel for each vm {vmuser_id: status}
  callingAPIs = {}; // calling IpAdapter API to the vm {vmuser_id: remotePort}

  constructor() {
    // autorun(() => {// for debug
    //   console.log(`__activeVM:`, toJS(this.activeVM));
    // });
    // autorun(() => {// for debug
    //   console.log(`__streamStatus:`, toJS(this.streamStatus));
    // });
    // autorun(() => {// for debug
    //   console.log(`__tunnelsStatus:`, toJS(this.tunnelsStatus));
    // });
    // autorun(() => {// for debug
    //   console.log(`__tunnels:`, toJS(this.tunnels));
    // });
    // autorun(() => {// for debug
    //   console.log(`__availableDevices:`, toJS(this.availableDevices));
    // });
    // autorun(() => {// for debug
    //   console.log(`__selectedDevice:`, toJS(this.selectedDevice));
    // });

    reaction(
      () => this.tunnels,
      tunnels => {
          if (this.isStreaming) {
            if(Object.keys(tunnels).length == 0){
              console.log("There is no tunnel, then stopping camera ...");
              this.stopCamera();
            }
          } else if(Object.keys(tunnels).length > 0) { // no stream
            // reset state of all tunnels
            console.log("Tunnel(s) with No stream, then Closing all tunnels");
            Object.keys({...this.tunnels})
              .forEach(tunnelKey => this.closeVideoStreamSSHtunnel({tunnelKey}))
          }
      }
    );

    reaction(
      () => this.isStreaming,
      isStreaming => {
        console.log("reaction isStreaming", isStreaming);

        if (isStreaming && !this.checkStreamStatus) {

          console.log("Camera is streaming, start periodic check");

          this.checkStreamStatus = function timeout(setStreamStatusToNoStream) {
            setTimeout(async() => {
              if(await window.api.isLocalVideoStreaming()){
                console.log("Camera is streaming ...");
                checkToStopRedirection();
                timeout(setStreamStatusToNoStream);
              }
              else
                setStreamStatusToNoStream();
            }, 10000);
          };

          this.checkStreamStatus(
            // function setStreamStatusToNoStream
            () => this.streamStatus = CameraController.STREAM_STATUS.NO_STREAM);

        }else if(!isStreaming && !!this.checkStreamStatus){

          console.log("Camera did not streaming, stopping periodic check");
          this.checkStreamStatus = null;

          console.log("Stopping stream app");
          this.stopCamera();

          // reset state of all tunnels
          console.log("Closing all tunnels");
          Object.keys({...this.tunnels})
            .forEach(tunnelKey => this.closeVideoStreamSSHtunnel({tunnelKey}))
        }
      }
    );
  }

  //@action get info need to establish SSH tunnel and API ip-camera
  async updateActiveVM(permId) {

    if(!permId) return;

    const perm = permission_list.get()[permId];
    if(!perm || !perm.vm.id) {
      this.activeVM = null;
      return;
    };
    if(perm.type === 'vmpool' || perm.type === 'vmpool-app') {
      this.activeVM = null;
      return;
    }

    const vm = vms_list.get()[perm.vm.id];
    if (!vm) {
      console.error(`Internal error at CameraController.updateActiveVM; Failed to get VM with PERM "${permId}".`);
      this.activeVM =  null;
      return;
    }

    if(!vm.rdp_params){
      let rdp_params = null
      try {
        if(is_ad_user.get()) {

          let q;

          if(perm.type == 'vmpool' || perm.type == 'vmpool-app') {
            q = `/api/advmpoolaccesss/${permission_list.get()[permId].id}/ssh_rdp_params`;
          } else {
            q = `/api/advmaccesss/${permission_list.get()[permId].id}/ssh_rdp_params`;
          }

          if(permission_list.get()[permId].app) {
            q += '?app=' + permission_list.get()[permId].app.id;
          }

          rdp_params = (await axios({
            url: q
          })).data;
        } else
          rdp_params = (await axios({
            url: `/api/permissions/${permId}/ssh_rdp_params`
          })).data;
        if (rdp_params)
          vm.rdp_params = rdp_params

      } catch(e) {
        console.error(`Internal error at CameraController.updateActiveVM; Failed to get RDP_PARAMS with PERM "${permId}".`);
        this.activeVM =  null;
        return;
      }
    }

    let tk;

    if(ad_user_id.get()) {
      tk = `${perm.vm.id}_${ad_user_id.get()}`;
    } else {
      tk = (perm.vmuser && perm.vmuser.id);
    }

    this.activeVM = { ...vm.rdp_params, tunnelKey: tk, description: perm.vm.descriptor, vm_id: perm.vm.id};

    // {
    //   public_dns: "dsadsa.v2cloud.com"
    //   ssh_port: 22
    //   ssh_user_password: "dsadsdsadsa"
    //   ssh_username: "vm60269"
    //   tunnelKey: 53289,
    //   description: "VM Name"
    //   vm_id: 123
    // }

    // initialize tunnel status
    if(!this.tunnelsStatus[this.activeVM.tunnelKey])
      this.tunnelsStatus[this.activeVM.tunnelKey] = CameraController.TUNNEL_STATUS.NO_TUNNEL;
  }

  //@action
  async controlByContext(vm = null) {

    if(vm) {
      this.activeVM = {...vm};

      // initialize tunnel status if not available
      if(!this.tunnelsStatus[vm.tunnelKey])
        this.tunnelsStatus[vm.tunnelKey] = CameraController.TUNNEL_STATUS.NO_TUNNEL;
    }

    if(!this.activeVM) return;

    // copy the current activeVM
    const currentActiveVM = {...this.activeVM};

    // lock other user actions based on status
    if(this.isRedirectionInProgress){
      console.log("camera redirection in progress ...");
      return;
    }

    if(this.isStreaming) {// toggle redirection
      if(!!vm && this.tunnelsStatus[vm.tunnelKey] == CameraController.TUNNEL_STATUS.TUNNELING) return;

      await this.toggleTunnel(currentActiveVM);
    }else{
      // get cameras and set selected camera
      await this.scan();

      if (!this.preferredCamera) { // open CameraSelectionModal, display list of cameras
        console.log('Do not have preferred camera');
        window.$('#setup-camera').modal('show');

        await new Promise(r => setTimeout(r, 1000));// delay 1s

        // waiting until the CameraSelectionModal closed
        let isCameraSelectionModalClosed = false;
        await poll(() => {
          isCameraSelectionModalClosed = !(window.$('#setup-camera')[0].className.includes("show"));
          return isCameraSelectionModalClosed;
        }, 1000); // interval
      }
      
      console.log("Preferred camera:", toJS(this.preferredCamera));
      await this.redirectCameraTo(currentActiveVM)
      return;
    }
  }

  //@action
  async redirectCameraTo(vm) {

    if(!vm) {
      if(this.activeVM)
        vm = {...this.activeVM};
      else{
        window.alert("No VM to redirect!");
        return;
      }
    }

    if(!(await this.startCamera())){
      window.alert("Failed to open camera!");
      return;
    }

    if(!(await this.openVideoStreamSSHtunnel(vm))){

      window.alert(`Failed to redirect camera to ${vm.description}!`);
      return;
    }
  }

  //@action
  async scan() {
    try {
      this.status = 'scanning';
      this.message = 'Scanning available cameras ...';

      this.availableDevices =  await window.api.scanCameras();

      const foundDevice = this.preferredCamera;

      if(this.availableDevices.length > 0) {
        // reselect the previous selected device
        if(foundDevice)
          this.updateSelectedDevice(foundDevice.id);
        else { // add a null device
          this.availableDevices.unshift(this.NULL_DEVICE);
          this.updateSelectedDevice(this.NULL_DEVICE.id);
        }
      } else
        this.selectedDevice = this.NULL_DEVICE;

      console.log("scan done! selected device:", toJS(this.selectedDevice));
      this.status = 'done';
      this.message = '';
    } catch (error) {
      this.status = 'failed';
      console.error(error);
    }
  }

  //@action
  syncSelectedDeviceFromLocalStorage(camera) {
    this.selectedDevice = camera;
  }

  //@action
  updateSelectedDevice(id) {

    try {
      const foundDevice = this.availableDevices.find(d => d.id == id);

      if(!foundDevice) throw new Error("Not found camera device with id " + id);

      this.selectedDevice = foundDevice;

    } catch (error) {
      console.error(error);
    }
  }

  //@action
  async startCamera() {

    // no camera device
    if(this.selectedDevice.id == this.NULL_DEVICE.id) return false;

    this.streamStatus = CameraController.STREAM_STATUS.STARTING_STREAM;

    this.message = "Start camera redirection ...";

    const result = window.isRPi
      ? await window.api.startCamera({devs: this.selectedDevice.devs.map(d => d.dev)})
      : await window.api.startCamera({camId: this.selectedDevice.id});

    if(result.error) { // failed to start local stream
      this.message = "Failed to start the camera redirection.\nPlease check you camera is connected!";
      this.streamStatus = CameraController.STREAM_STATUS.NO_STREAM;
    }

    if((this.streamStatus == CameraController.STREAM_STATUS.STARTING_STREAM)){
      this.streamStatus = CameraController.STREAM_STATUS.STREAMMING;
      return true;
    }else
      return false;
  }

  //@action
  async stopCamera() {

    this.streamStatus = CameraController.STREAM_STATUS.STOPPING_STREAM;

    await window.api.stopCamera();
    console.log("Stopped camera stream");

    this.streamURL = '';
    this.streamStatus = CameraController.STREAM_STATUS.NO_STREAM;
    this.message = "";
  }

  //@action
  updateTunnels(){
    this.tunnels =  window.api.reverseTunnels();
  }

  //@action
  async toggleTunnel({
    ssh_port,
    ssh_username,
    public_dns,
    ssh_user_password,
    tunnelKey,
    description,
    vm_id
  }) {

    if(this.tunnelsStatus[tunnelKey] == CameraController.TUNNEL_STATUS.TUNNELING)
      return await this.closeVideoStreamSSHtunnel({tunnelKey})
    else if(this.tunnelsStatus[tunnelKey] == CameraController.TUNNEL_STATUS.NO_TUNNEL)
      return await this.openVideoStreamSSHtunnel({
        ssh_port,
        ssh_username,
        public_dns,
        ssh_user_password,
        tunnelKey,
        description,
        vm_id
      });

    //else
    console.error("Tunnel action in progress ...");
    return false;
  }

  //@action
  async closeVideoStreamSSHtunnel({tunnelKey}) {
    if(!tunnelKey) return;

    this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.STOPPING_TUNNEL;

    await window.api.closeReverseSSHtunnel(tunnelKey);
    this.updateTunnels();

    this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.NO_TUNNEL;
  }

  //@action
  async openVideoStreamSSHtunnel({
    ssh_port,
    ssh_username,
    public_dns,
    ssh_user_password,
    tunnelKey,
    description,
    vm_id
  }) {

    this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.STARTING_TUNNEL;

    // get port
    const localPort = await window.api.cameraStreamPort();
    let currentRemotePort = null;

    this.message = "Openning secure connection ...";

    console.log("localPort", localPort );

    // start reverse SSH tunnel
    try {
      await window.api.openReverseSSHtunnel({
        tunnelKey,
        sshUserPassword: ssh_user_password,
        sshUsername: ssh_username,
        publicDns: public_dns,
        sshPort: ssh_port,
        proxyPrivateIP: PROXY_PRIVATE_IP,
        localPort,
        maxAttempt: 5});

      this.updateTunnels();

      currentRemotePort = this.tunnels[tunnelKey].remotePort;

      const remoteURL = `http://${PROXY_PRIVATE_IP}:${this.tunnels[tunnelKey].remotePort}/${[ window.isRPi ? "stream" : "mjpeg" ]}`;

      // call API for setting up remote VM, if failed close the tunnel
      this.message = `Set up virtual camera on ${description}`;

      //get credentials
      const streamInfo = window.api.cameraStreamInfo();
      console.log('streamInfo', streamInfo);

      // API behaviour: call POST requests in order 1, 2, 3 => receive successful respones 2, 3, 1 BUT only the 1st request affect
      // => wait until a request finish then make another request

      let done = false;
      await poll(() => {
        console.log(`Wait to call API for ${tunnelKey} - Local Port ${localPort} - Remote Port ${currentRemotePort}`);
        done = !this.callingAPIs.hasOwnProperty(tunnelKey);
        return done;
      }, 1000); // interval

      // check before calling API
      if(!(await this.checkValidRedirectRequest({localPort, currentRemotePort, tunnelKey}))) {
        console.log(`Cancel calling API for ${tunnelKey} - Local Port ${localPort} - Remote Port ${currentRemotePort}`);
        return true;
      }

      this.callingAPIs[tunnelKey] = currentRemotePort;
      console.log(`Calling API for ${tunnelKey} - Local Port ${localPort} - Remote Port ${currentRemotePort}`);
      const resultIpApdapter = await axios({
        timeout: 200000,
        method: 'post',
        url: is_ad_user.get() ? `/api/adusers/${ad_user_id.get()}/setup_ip_camera_on_vm`
                              : `/api/vmusers/${tunnelKey}/setup_vmuser_ip_camera/`,
        data: {
          url: remoteURL,
          username: streamInfo.username,
          password: streamInfo.password,
          vm: vm_id
        }
      });

      console.log('resultIpApdapter', resultIpApdapter);
      if (!(resultIpApdapter.status == 200 || resultIpApdapter.status == 202))
        throw new Error("Failed when setting up IP Camera Adapter on your cloud computer");

      if(await this.checkValidRedirectRequest({localPort, currentRemotePort, tunnelKey})) {
        this.tunnelsStatus[tunnelKey] = CameraController.TUNNEL_STATUS.TUNNELING;
        this.message = `Successfully redirect camera to ${description}`;
        console.log(`Successfully redirect camera to ${tunnelKey} - Local Port ${localPort} - Remote Port ${currentRemotePort}`);
      }

      return true;
    }catch(err) {
      if(await this.checkValidRedirectRequest({localPort, currentRemotePort, tunnelKey})){
        console.error(`Failed to create SSH tunnel for video stream \nError: ${err.message}`);
        this.message = "Failed to opening a secure connection!";
        await this.closeVideoStreamSSHtunnel({tunnelKey});
        return false;
      }

      // don't care errors
      console.error(`Don't care errors: Failed to create SSH tunnel for video stream \nError: ${err.message}`);
      return true;
    }finally{
      if(this.callingAPIs.hasOwnProperty(tunnelKey)){
        console.log(`Remove callingAPIs[${tunnelKey}] `, this.callingAPIs);
        delete this.callingAPIs[tunnelKey];
      }
    }
  }

  //@action
  async checkValidRedirectRequest({localPort, currentRemotePort, tunnelKey}){
    return this.isStreaming &&
      localPort ==  await window.api.cameraStreamPort() &&
      (!this.tunnels.hasOwnProperty(tunnelKey) ||
      currentRemotePort == this.tunnels[tunnelKey].remotePort) && // case: user manually turns off camera stream then click redirect
      this.tunnelsStatus[tunnelKey] == CameraController.TUNNEL_STATUS.STARTING_TUNNEL// case: when other actions change tunnelsStatus
  }

  //@computed
  get preferredCamera() {
    return (this.selectedDevice.id == this.NULL_DEVICE.id)
      ? null
      : this.availableDevices.find(cam => cam.name == this.selectedDevice.name);
  }

  //@computed
  get isStreaming() {
    return this.streamStatus == CameraController.STREAM_STATUS.STREAMMING;
  }

  //@computed
  get isRedirectionInProgress() {
    return this.status == 'scanning'
      || this.streamStatus == CameraController.STREAM_STATUS.STARTING_STREAM
      || this.streamStatus == CameraController.STREAM_STATUS.STOPPING_STREAM
      || this.tunnelsStatus[this.activeVM.tunnelKey] == CameraController.TUNNEL_STATUS.STARTING_TUNNEL
      || this.tunnelsStatus[this.activeVM.tunnelKey] == CameraController.TUNNEL_STATUS.STOPPING_TUNNEL;
  }

  //@computed

  //@computed
  get iconStatus() {// TopBar icon
    let result = 'fa fa-video-camera';

    if(!!this.activeVM){

      if(this.isRedirectionInProgress)
          result = 'fa fa-spinner fa-spin fa-fw';
    }

    return result;
  }

  //@computed
  get iconStyle() {// TopBar icon style
    let result = {color: "white"};

    if(!!this.activeVM){
      const status = this.tunnelsStatus[this.activeVM.tunnelKey];
      console.log('iconStyle for status', status);

      if(status == CameraController.TUNNEL_STATUS.TUNNELING)
        result = {color: "red"};
    }
    return result;
  }

  //@computed
  get actionStatus() {
    let result = "Redirect camera";

    if(!!this.activeVM){
      const status = this.tunnelsStatus[this.activeVM.tunnelKey];
      console.log('actionStatus', status);

      if(this.status == 'scanning')
        result = "Scanning ...";
      else if(this.streamStatus == CameraController.STREAM_STATUS.STARTING_STREAM)
        result = "Starting ...";
      else if(status == CameraController.TUNNEL_STATUS.NO_TUNNEL)
        result = "Redirect camera";
      else if(status == CameraController.TUNNEL_STATUS.TUNNELING)
        result = "Stop redirection";
      else
        result = "Redirecting ...";
    }

    return result;
  }
}

decorate(CameraController, {
  status: observable,
  message: observable,
  activeVM: observable,
  tunnels: observable,
  tunnelsStatus: observable,
  streamStatus: observable,
  availableDevices: observable,
  selectedDevice: observable,
  scan: action.bound,
  updateSelectedDevice: action.bound,
  syncSelectedDeviceFromLocalStorage: action.bound,
  controlByContext: action.bound,
  updateActiveVM: action.bound,
  updateTunnels: action.bound,
  toggleTunnel: action.bound,
  openVideoStreamSSHtunnel: action.bound,
  closeVideoStreamSSHtunnel: action.bound,
  checkValidRedirectRequest: action.bound,
  preferredCamera: computed,
  isStreaming: computed,
  isRedirectionInProgress: computed,
  actionStatus: computed,
  iconStatus: computed,
  iconStyle: computed,
});

export const cameraController = new CameraController();

observe(active_perm, () => {

  cameraController.updateActiveVM(active_perm.get());

  //console.log('active_perm is ', active_perm.get());

});

const checkToStopRedirection = async function(controller = cameraController) {
  if(await window.api.isRDPrunning()){
    console.log("Do not stop the stream because RDP is running");
    return;
  }

  // get all active vmuser.id (tunnelKey) from vm_cache
  const activeVMuserIds = [...vm_cache.keys()]
    .filter(perm_id => permission_list.get()[perm_id])
    .map(perm_id => ad_user_id.get() ? `${permission_list.get()[perm_id].vm.id}_${ad_user_id.get()}`
                                     : String(permission_list.get()[perm_id].vmuser.id));
  console.log("activeVMuserIds", activeVMuserIds);

  // filter tunnelsStatus that has TUNNELING status and not an active connection
  const shouldBeClosedTunnelKeys = (Object.keys(controller.tunnelsStatus))
    .filter(key =>
      controller.tunnelsStatus[key] == CameraController.TUNNEL_STATUS.TUNNELING
      && !activeVMuserIds.includes(key));
  console.log("shouldBeClosedTunnelKeys", shouldBeClosedTunnelKeys);

  shouldBeClosedTunnelKeys.forEach(tunnelKey => cameraController.closeVideoStreamSSHtunnel({tunnelKey}));
}
