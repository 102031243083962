import React from 'react';
import {observer} from 'mobx-react';

import axios from 'axios';
import * as auth from '../../auth.js';

import {vms_list, permission_list} from '../../userData.js';
import { open_vm } from '../../useRDP.js';
import {signoff} from '../../signoff.js';


class Signout extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      loading: false
    };

  }

  // Identical to the code within VMUser.js
  async _signoff() {

    if(!window.confirm('Are you sure you want to sign off of this VM?')) return;

    this.setState({loading: true});

    try {
      await signoff(this.props.perm_id);
    } catch(e) {
      console.error(e);
    }

    this.setState({loading: false});

  }


  render() {

    if(this.state.loading) {

      return <i className="fa fa-spinner fa-pulse" style={{float: 'right', marginTop: '9px', marginRight: '6px'}}></i>;

    } else {

      return <i onClick={() => this._signoff()} className="fa fa-sign-out refresh-button status-icon-bg-hover-only" aria-hidden="true" title="Sign off" style={{float: 'right', marginTop: '7px', marginRight: '6px'}}></i>;

    }

  }

}

export const ManagedUserList = observer(() =>

  <>

    <div className="computers-list application-list" id="application-list" style={{paddingTop: '10px'}}>
    	<div className="accordion">
    		<div className="card">
    			<div className="card-header">
    				<h4 className="mb-0" style={{display: "inline"}}>
    				<i className="fa fa-desktop" aria-hidden="true" style={{color: "#fff"}}></i>Connections
    				</h4>
    			</div>
    		</div>
    	</div>
    </div>
    <ul className="new-connection-list">

      {
        Object.values(vms_list.get()).map(({descriptor: vm_description, id}) =>


        <li key={id}>
          <div className="new-connect">
            <h4 className="new-connect-name">{vm_description}</h4>

            {
              Object.entries(permission_list.get())
                .filter(([_, {vm}]) => vm.id === id)
                .map(([perm_id, {vm, vmuser, type, app}]) => (

              <div key={perm_id} style={{marginBottom: '-4px'}}>
                <a
                  key={perm_id}
                  href="javascript: void(0)"
                  onClick={() => open_vm(perm_id)}
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "215px",
                    overflow: "hidden"
                  }}>
                    <i
                    className={
                      type === 'vm' ? "fa fa-desktop" : (
                        type === 'app' ? 'fa fa-window-maximize' : (
                          type === 'vmpool' ? "fa fa-sitemap" : (
                            type === 'vmpool-app' ? 'fa fa-window-restore' : 'fa fa-question'
                          )
                        )
                      )
                    }
                    aria-hidden="true"
                    style={{color: "rgb(255, 255, 255)", padding: 0}}>
                    </i>
                    <span style={{marginLeft: 10}}>
                      {(app && app.display_name) || "Desktop"}
                    </span>
                </a>

                <Signout perm_id={perm_id}/>
              </div>

            ))}

          </div>
      	</li>

      )}

    </ul>

  </>

)
