import React from 'react';

import {RDPModal} from './RDPModal.js';
import {ShareScreenModal} from './ShareScreenModal.js';
import {WifiModal} from './rpiClient/WifiModal.js';
import {SettingsModal} from './SettingsModal.js';
import {displayController} from '../rpiClient/DisplayController.js';
import {DisplayModal} from './rpiClient/DisplayModal.js';
import {CameraSelectionModal} from './CameraSelectionModal.js';
import {cameraController} from '../CameraController.js';
import {InstallDependenciesModal} from './InstallDependenciesModal';

export const Modals = () => (

  <>
    <ShareScreenModal/>
    <div className="modal fade" id="open-in-desktop" tabIndex="-1" role="dialog" aria-labelledby="open-ind-desktop" aria-hidden="true">
       <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
             <div className="modal-header">
                <h5 className="modal-title inner-modal-dashboard">
                   Launching…
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
             </div>
             <div className="modal-body">
                <div className="the-content-inner text-dark">
                   <div className="row ">
                      <div className="col-md-10">
                         <p>If nothing prompts from browser, <a href="https://v2cloud.com/download" style={{textDecoration: 'underline', color: '#00438b', fontWeight: '600'}}>download and run V2 Cloud</a> directly.</p>
                      </div>
                      <div className="col-md-2 align-self-center">
                         <div className="text-right">
                            <a
                              onClick={() => window.$('#live-help').click()}
                              href="javascript:void(0);" className="btn btn-primary">
                              Get Help
                            </a>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div className="modal fade" id="transfer-files" tabIndex="-1" role="dialog" aria-labelledby="transfer-files" aria-hidden="true">
       <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
             <div className="modal-header">
                <h5 className="modal-title inner-modal-dashboard">
                   <i className="fa fa-files-o" aria-hidden="true"></i> Transfer Files
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
             </div>
             <div className="modal-body">
                <div className="the-content-inner text-dark">
                   <p className="sub-title-modal-for-uploading-downloading">Learn how to upload and download files with V2 Cloud.</p>
                   <div className="row modal-row-files-transfer-section">
                      <div className="col-md-6">
                         <div className="files-section upload-section">
                            <button type="button" className="btn control-buttons modal-buttons" data-toggle="modal" data-target="#upload-files" style={{display: 'inline-flex', textAlign: 'left', lineHeight: '1.5 !important'}}>
                            <i className="fa fa-cloud-upload" aria-hidden="true" style={{fontSize: '22px', verticalAlign: 'middle', paddingRight: '7px'}}></i> How to upload files (from local PC to the Cloud)
                            </button>
                         </div>
                      </div>
                      <div className="col-md-6">
                         <div className="files-section">
                            <button type="button" className="btn control-buttons modal-buttons" data-toggle="modal" data-target="#download-files" style={{display: 'inline-flex', textAlign: 'left', lineHeight: '1.5 !important'}}>
                            <i className="fa fa-cloud-download" aria-hidden="true" style={{fontSize: '22px', verticalAlign: 'middle', marginRight: '7px'}}></i> How to download files (from Cloud to local PC)
                            </button>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div className="modal fade" id="download-files" tabIndex="-1" role="dialog" aria-labelledby="download-files" aria-hidden="true">
       <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
             <div className="modal-header">
                <h5 className="modal-title inner-modal-dashboard">
                   <i className="fa fa-cloud-download" aria-hidden="true"></i> How to download files (from Cloud to local PC)
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
             </div>
             <div className="modal-body">
                <p className="sub-title-modal-for-uploading-downloading">Right click on the file you want to download and click "Download to local PC". The file will be downloaded to your local Downloads folder via the browser. </p>
                <img src="images/download-files-v2.gif"/>
             </div>
          </div>
       </div>
    </div>
    <div className="modal fade" id="upload-files" tabIndex="-1" role="dialog" aria-labelledby="upload-files" aria-hidden="true">
       <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
             <div className="modal-header">
                <h5 className="modal-title inner-modal-dashboard">
                   <i className="fa fa-cloud-upload" aria-hidden="true"></i> How to upload files (from local PC to the Cloud)
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
             </div>
             <div className="modal-body">
                <p className="sub-title-modal-for-uploading-downloading">Drag and drop files from your local PC to the application. The files will be transfered in the "Downloads" folder inside the Cloud computer.</p>
                <img src="images/upload-1.gif"/>
             </div>
          </div>
       </div>
    </div>
    <div className="modal fade" id="after-upload-files" tabIndex="-1" role="dialog" aria-labelledby="after-upload-files" aria-hidden="true">
       <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
             <div className="modal-header">
                <h5 className="modal-title inner-modal-dashboard">
                   <i className="fa fa-cloud-upload" aria-hidden="true"></i> File Uploaded
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
             </div>
             <div className="modal-body">
                <p className="sub-title-modal-for-uploading-downloading">Your file is uploaded to the Downloads folder of your Cloud comptuer.</p>
                <img src="images/uploaded-file.jpg"/>
                { /* <div className="section-after-image">
                   <input type="checkbox" name="checkbox-selection" id="show-message"/><label style={{fontSize: '16px', paddingLeft: '10px'}}>Do not show this message again.</label>
                </div> */ }
             </div>
          </div>
       </div>
    </div>

    <RDPModal/>

    { window.isRPi && <WifiModal/> }

    { window.isElectron && <SettingsModal/> }

    { window.isRPi && <DisplayModal controller={displayController}/> }

    { window.isElectron && <CameraSelectionModal controller={cameraController}/> }

    { window.isLinux && <InstallDependenciesModal/> }
  </>

);
