import {observable, observe, computed, action, autorun} from 'mobx';

import {discard_data} from './userData.js';
import {open_vm} from './useRDP.js';
import {vm_cache} from './components/util/WebconnectFrame.js';
import {persistent_observable} from './storage.js';
import {hash} from './url.js';
import {logged_in} from './auth.js';
import {link as share_screen_link} from './components/ShareScreenModal.js';
import {active_perm} from './activeVM.js';


const update_from_hash = () => {

  if(!logged_in.get()) return;


  const [base, perm_id, ...others] = hash.get().split('/');

  if(base !== '#' || others.length > 0 || !perm_id) {

    active_perm.set();

  } else {

    open_vm(perm_id);

  }

};


autorun(update_from_hash);



const update_to_hash = () => {

  if(!logged_in.get()) return;


  if(!active_perm.get()) {

    hash.set('#/');

  } else {

    hash.set('#/' + active_perm.get())

  }

};

autorun(update_to_hash);


export const discard_active_state = () => {

  // flush open vm cache
  vm_cache.clear();

  // Deselect active_vm on logout

  active_perm.set();
  // active_vm_frame.set();

};


autorun(() => {

  if(!logged_in.get()) {

    discard_active_state();

  }

});
