import React from 'react';
import { observer } from 'mobx-react';
import { settingsController }  from '../SettingsController';
import { cameraController } from '../CameraController.js';
import { CameraSelection } from './CameraSelection.js';
import { MultimonSelect } from './MultimonSelect.js';

export const WindowsSettings = observer(() => {

  const windows_settings = settingsController.getSettings();

  return ( <>
    <h5 className="card-title px-3 py-1" style={{ fontWeight:"bold", color:"#00438b"}}><i className="fa fa-video-camera video-cam-icon" aria-hidden="true"></i>Video</h5>
    <div className="card">
{/* Video and Audio */}

      <div className="card-body pb-0 pt-0 choose-camera-option">
        <h6 className="card-title" style={{color:"#00438b", fontWeight:"bold"}}>Webcam</h6>
            <CameraSelection controller={cameraController}/>
      </div>
    </div>
    {/* RDP */}
      <div className="card-body pb-0 pt-0">
        <div className="ml-3">
          <div className="form-check form-check-inline col-12 camera-selection-option">
            <input className="form-check-input camera-selection-option" type="checkbox" id="redirectCamera"
            defaultChecked={windows_settings.redirectCamera}
            value="redirectCamera"
            onChange={e => settingsController.toggleOptionByValue(e.target.value)}
            />
            <label className="form-check-label noselect" htmlFor="redirectCamera">
              Always redirect camera when using RDP
            </label>
          </div>
        </div>
      </div> {/* RDP End */}

{/* Connection */}
    <h5 className="card-title px-3 py-1 mt-4 connection-title" style={{ fontWeight:"bold", color:"#00438b"}}><i className="fa fa-desktop connection-icon" aria-hidden="true"></i>Connection</h5>
    <div className="card">

      <div className="form-check form-check-inline card-body pb-3 pt-0">
        <input className="form-check-input" type="checkbox" id="useRDPcheckbox" data-toggle="collapse" data-target="#useRDPoptions"
        checked={windows_settings.useRDP}
        value="useRDP"
        onChange={e => settingsController.toggleOptionByValue(e.target.value)}
        />
        <label className="form-check-label" htmlFor="useRDPcheckbox">
          Always use RDP
        </label>
      </div>

      <div className="form-check form-check-inline card-body pb-3 pt-0">
        <input className="form-check-input" type="checkbox" id="useNetworkIP"
        checked={windows_settings.useNetworkIP}
        value="useNetworkIP"
        onChange={e => settingsController.toggleOptionByValue(e.target.value)}
        />
        <label className="form-check-label" htmlFor="useNetworkIP">
          Use Private Network IP
        </label>
      </div>

      <div className="form-check form-check-inline card-body pb-3 pt-0">
        <input className="form-check-input" type="checkbox" id="useMultimon"
        checked={windows_settings.useMultimon}
        value="useMultimon"
        onChange={e => settingsController.toggleOptionByValue(e.target.value)}
        />
        <label className="form-check-label" htmlFor="useMultimon">
          Use Multimonitor
        </label>
      </div>

      {  window.api.isWindowsOS &&
        windows_settings.useMultimon &&

        <div className="form-check form-check-inline card-body pb-3 pt-0">
          <input className="form-check-input" type="checkbox" id="useMultimonSelection"
          checked={windows_settings.useMultimonSelection}
          value="useMultimonSelection"
          onChange={e => settingsController.toggleOptionByValue(e.target.value)}
          />
          <label className="form-check-label" htmlFor="useMultimonSelection">
            Use Multimonitor Selection (Beta)
          </label>
        </div>
      }

      { window.api.isWindowsOS &&
        windows_settings.useMultimon &&
        windows_settings.useMultimonSelection &&
        <MultimonSelect/>
      }

    </div>

{/* Advanced */}
    <h5 className="card-title px-3 py-1 mt-4 connection-title" style={{ fontWeight:"bold", color:"#00438b"}}><i className="fa fa-cog connection-icon" aria-hidden="true"></i>Advanced</h5>
    <div className="card">
      { window.api.isWindowsOS &&
        <div className="form-check form-check-inline card-body pb-3 pt-0" style={{display: 'block'}}>
          <button
            style={{
              border: 0,
              backgroundColor: 'rgb(0, 75, 156)',
              color: 'white',
              padding: '8px',
              borderRadius: '4px'
            }}
            onClick={() => window.api.default_rdp_configurator()}>Modify default RDP parameters</button>

          <p style={{
            marginTop: '15px',
            fontSize: '15px',
            textDecoration: 'italic',
            fontStyle: 'italic'
          }}>Use the popup to change the default RDP parameters as desired. Then, in the <strong>General</strong> tab, under <strong>Connection settings</strong>, click <strong>Save</strong>. The button to connect will not be functional.</p>
        </div>
      }

      <div className="form-check form-check-inline card-body pb-3 pt-0" style={{display: 'block'}}>
        <button
          style={{
            border: 0,
            backgroundColor: 'rgb(0, 75, 156)',
            color: 'white',
            padding: '8px',
            borderRadius: '4px'
          }}
          onClick={() => window.api.default_rdp_explorer()}>Open default RDP parameters in file explorer</button>

        {
          !window.api.isWindowsOS &&

          <p style={{
            marginTop: '15px',
            fontSize: '15px',
            textDecoration: 'italic',
            fontStyle: 'italic'
          }}>Edit this file with a text editor.</p>
        }
      </div>

      <div className="form-check form-check-inline card-body pb-3 pt-0">
        <button
          style={{
            border: '0px',
            background: 'none',
            textDecoration: 'underline',
            color: '#5656bb'
          }}
          onClick={() => window.api.default_rdp_reset()}>Reset RDP parameters to defaults</button>
      </div>

    </div> {/* Connection End */}

  </>
  );
});
