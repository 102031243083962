import React, { useEffect } from 'react';
import { INSTALL_STATUS, installDependenciesController } from '../InstallDependenciesController';
import { observer } from 'mobx-react';

export const InstallDependenciesModal = observer(() => {

	useEffect(() => {
		//check dependencies
		window.isLinux && installDependenciesController.checkDependencies();
  }, [])
 
	return (
		installDependenciesController.visible &&
		<div className="modal fade" id="install-dependencies-modal" tabIndex="-1" role="dialog" aria-labelledby="settings-modal" aria-hidden="true">
			<div className="modal-dialog modal-dialog modal-md" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title inner-modal-dashboard">
							<i className="fa  fa-download" aria-hidden="true" style={{ paddingRight: '5px' }}></i>Install Dependencies
						</h5>
					</div>
					<div className="modal-body px-4 pt-4 pb-0">

						{ // TITLE
							!!installDependenciesController.message 
								&& <h6>{installDependenciesController.message}</h6>
						}

						{ // STATUS
							!!installDependenciesController.statusMessage 
							&& <h6 className={
								installDependenciesController.status == INSTALL_STATUS.FAILED 
									? "text-danger"
									: installDependenciesController.status == INSTALL_STATUS.INSTALLED
										? "text-success"
										: "" 
							}>
								{installDependenciesController.statusMessage}</h6>
						}

					</div>
					<div className="modal-footer">
						{ (installDependenciesController.status == INSTALL_STATUS.INSTALL ||
								installDependenciesController.status == INSTALL_STATUS.FAILED)
								? <YesNoButtons/>
								: installDependenciesController.status == INSTALL_STATUS.INSTALLED
									? <CloseButton/>
									: <ProgressBar/>
						}
					</div>
				</div>
			</div>
		</div>)

});

const YesNoButtons = () => (
	<>
		<button type="button" data-dismiss="modal" aria-label="Close" className="btn btn-secondary" >No</button>
		<button type="button" className="btn btn-primary" onClick={installDependenciesController.installDependencies}>Yes</button>
	</>
)

const CloseButton = () => <button type="button" data-dismiss="modal" aria-label="Close" className="btn btn-primary" >Close</button>

const ProgressBar = () => (
	<div className="progress" style={{width: "100%"}}>
		<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width: "100%", WebkitAnimation: "progress-bar-stripes 1s linear infinite"}}></div>
	</div>
)

