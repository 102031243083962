import React from 'react';
import {observer} from 'mobx-react';
import {permission_list, vms_list} from '../../userData.js';
import {active_perm} from '../../activeVM.js';
import {vm_cache} from '../util/WebconnectFrame.js';


export const ActiveConnections = observer(() => {

  // Do not display if nothing is in the VM cache
  if([...vm_cache.entries()].length === 0) return null;

  const vm_display_name = perm_id => {

    if(perm_id.startsWith('pool')) {

        // https://app.v2cloud.com/#/pool_70
        // https://app.v2cloud.com/#/pool_70_82

        const [_, pool_id] = perm_id.split('_')

        perm_id = pool_id

    }


    if(!permission_list.get()[perm_id]) {
      // if hash is embedded into url but not on permission list
      return ''
    }

    let vm_desc = vms_list.get()[permission_list.get()[perm_id].vm.id];

    vm_desc = vm_desc && vm_desc.descriptor;


    let type = permission_list.get()[perm_id].type;

    const perm_desc = (type === 'vm' || type === 'vmpool') ? "Desktop" : permission_list.get()[perm_id].app.display_name;


    if(vm_desc) {

      return perm_desc + ', ' + vm_desc;

    } else {

      return perm_desc;

    }

  };


  return (

    <div className='scroll-inner'>
      <div className="computers-list" id="the-active-vms-list">
         <div className="accordion">
            <div className="card">
               <div className="card-header">
                  <h4 className="mb-0">
                     <i className="fa fa-desktop" aria-hidden="true" style={{color: "#fff"}}></i> Active Connections
                  </h4>
               </div>
            </div>
          </div>


          <ul className="computer-list list-items user-pc active-connections" id="active-connection-list">

            {

              [...vm_cache.keys()]
            //    .filter(perm_id => perm_id.startsWith('pool') || permission_list.get()[perm_id])
                .map(perm_id =>

                <li key={perm_id}
                  className="the-menu-parent active-connections"
                  style={{
                    backgroundColor: active_perm.get() === perm_id ? "#007bff" : "inherit",
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    justifyContent: 'space-between'
                  }}>

                  <a href="javascript:void(0);"
                    title={vm_display_name(perm_id)}
                    onClick={() => active_perm.set(perm_id)}
                    style={{
                      overflow: 'hidden',
                      minWidth: 0,
                      textOverflow: 'ellipsis',
                      padding: '3px',
                      paddingLeft: '15px'
                    }}>
                    {vm_display_name(perm_id)}
                  </a>

                  <div className="status-bar" id="status-bar">
                    <i className="fa-custom fa fa-times signout-button status-icon-bg-hover-only"
                      aria-hidden="true"
                      title="Close"
                      style={{paddingRight: '6px'}}
                      onClick={e => {

                        // to refresh:
                        // active_perm.set(); active_perm.set(perm_id);

                        vm_cache.delete(perm_id);
                        if(active_perm.get() === perm_id) active_perm.set();

                      }}>
                    </i>
                  </div>
                </li>

              )

            }

          </ul>

       </div>
    </div>

  );

})
