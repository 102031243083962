import {active_vm_frame} from '../activeVM.js';
import {autorun} from 'mobx';


window.setTimeout(() => autorun(() => {

  bind_clear_auth(active_vm_frame.get());

}), 0);



const bind_clear_auth = iframe => {

  if(!iframe) return;


  if(!iframe.contentWindow.clear_auth_interval) {

    iframe.contentWindow.clear_auth_interval = iframe.contentWindow.setInterval(() => {

      if(iframe.contentWindow.localStorage.getItem("GUAC_AUTH") !== null) {

        iframe.contentWindow.localStorage.removeItem("GUAC_AUTH");

      }

    }, 1000);

  }


};
