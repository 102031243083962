import React from 'react';

import {observer} from 'mobx-react';
import {observable} from 'mobx';

import {vms_list, user_list, is_managed, fetch_permission, load_permissions_and_vms} from '../../userData.js';
import {vm_cache} from '../util/WebconnectFrame.js';
import {VMRoot} from './VMRoot.js';

import {ManagedUserList} from './ManagedUserList.js';


export const search_filter = observable.box('');


const filter_by_search = ({id, descriptor}) =>

  descriptor.toLowerCase().includes(search_filter.get().toLowerCase()) ||

  user_list.get()[id] && console.log(id, search_filter.get(), user_list.get());



export const VMList = observer(() =>

  <HTML>

    {
      !is_managed.get() ?

        Object.values(vms_list.get())
          .filter(({is_vmpool}) => !is_vmpool)
          .filter(filter_by_search)
          .map(api => (

        <VMRoot key={api.id} api={api}/>

        ))

      :

        <ManagedUserList/>

  }

  </HTML>
);


class HTML extends React.Component {

  constructor(props) {
    super(props);

    this.state = {loading: false};
  }

  async refreshList() {

    this.setState({loading: true});

    try {

      await load_permissions_and_vms();

    } catch(e) {

      console.error(e);

    }

    this.setState({loading: false});

  }

  componentDidMount() {
    window.$('.slimscroll').slimscroll({
      height: '100%',
    }).css({
      overflowY: 'hidden',
      width: '1000px',
      pointerEvents: 'none'
    });

    window.$('.slimScrollDiv').css({
      overflow: 'visible'
    })
  }

  render() {

    return (
      <div className="scroll-adjust-section"
        style={{
          minHeight: '0px',
          marginTop: '20px',
        }}>
         <div className="scroll-inner slimscroll" style={{height: '100%',}}>
         <div style={{marginTop: '-20px', width: '244px', pointerEvents: 'auto'}}>

          { !is_managed.get() &&

            <div className="computers-list" id="the-computers-list">
               <div className="accordion">
                  <div className="card">
                     <div className="card-header">
                        <h4 className="mb-0" style={{display: 'inline'}}>
                           <i className="fa fa-desktop" aria-hidden="true" style={{color: "#fff"}}></i> Computers
                        </h4>
                        {/* fa fa-spinner fa-pulse */}
                        <i onClick={() => this.refreshList()} className={this.state.loading ? "fa fa-spinner fa-pulse" : "fa fa-refresh refresh-button status-icon-bg-hover-only"} aria-hidden="true" style={{
                            float: "right",
                            marginRight: "23px"
                        }}></i>
                     </div>
                  </div>
               </div>
            </div>

          }

            <div className="the-menu-list">
               <ul className="computer-list" id="computer-user-lists">
                {this.props.children}
              </ul>
           </div>
           </div>
        </div>
      </div>


    );

  }

}



//
// export const VMList = observer(() =>
//
//   <div className="the-menu-list">
//     <ul className="computer-list" id="computer-user-lists">
//
//       {
//
//         vm_list.get().filter(filter_by_search).map(({domain, description, id}) =>
//
//           <li
//             className="the-menu-parent"
//             key={id}
//             style={{
//               ...(active_vm.get() === String(id) && { backgroundColor: 'rgba(0,0,0,0.2)' })
//             }}>
//
//             <a onClick={() => {
//               if(active_vm.get() === String(id)) {
//                 active_vm.set()
//                 active_user.set()
//               } else {
//                 active_vm.set(String(id))
//               }
//             }}>
//               <em>{domain}</em>: {description}
//             </a>
//
//             {
//
//               user_list.get()[id] &&
//
//                 <ul>
//                   {user_list.get()[id].map(({username, user_id}) =>
//
//                     <li
//                       onClick={() => {
//                         (active_user.get() === String(user_id)) ? active_user.set() : active_user.set(String(user_id))
//                       }}
//                       key={username}
//                       style={{
//                         ...(active_vm.get() === String(id) && active_user.get() === String(user_id)
//                           && { backgroundColor: 'rgba(0, 0, 0, 0.2)' })
//                       }}>
//
//                       {username}
//                     </li>
//
//                   )}
//                 </ul>
//
//             }
//
//           </li>
//
//         )
//
//       }
//
//     </ul>
//   </div>
//
// );
