import {observe, observable} from 'mobx';


export const getItem = name => (window.isElectron && window.api.getStorageItem) ?

  window.api.getStorageItem(name) :

  window.sessionStorage.getItem(name);


export const setItem = (name, value) => (window.isElectron && window.api.setStorageItem) ?

  window.api.setStorageItem(name, value) :

  window.sessionStorage.setItem(name, value);



const observers = {};


// maintains observer state across sessions

// note that all the values must be JSON, so undefined is not allowed

export const persistent_observable = (name, default_ = '') => {

    const debug = true;


    if(name in observers) {

        console.error('Attempting to create duplicate state field "' + name + '"');
        return;

    }


    debug && console.log(name + ':', getItem(name));


    const observer = observable.box(

        JSON.parse(getItem(name)) || default_,

        {
            deep: false
        }

    );



    observers[name] = observer;

    observe(observer, () => {

        debug && console.log(name + ':', observer.get());

        setItem(name, JSON.stringify(observer.get()))

    });

    return observer;

};


// Debug utility

window.clear = () => window.isElectron ?

  window.api.clearStorageItems() :

  window.localStorage.clear();



export const serialize_state = () =>

    JSON.stringify(Object.entries(observers).map(

        ([name, observer]) => [name, observer.get()]

    ));


export const deserialize_state = state =>

    JSON.parse(state).forEach(([name, value]) => {

        if(!(name in observers)) {

            const err = `Attempting to deserialize non-existent observer ${name}. Application is probably not synchronized.`;

            console.error(err)

            window.isDebug && alert(err);

        } else {

            observers[name].set(value)

        }

    });


export const serialize_state_obfuscated = () =>

    window.btoa(serialize_state());


export const deserialize_state_obfuscated = state =>

    deserialize_state(window.atob(state));
