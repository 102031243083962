window.isElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

// window.api.isRPi is undefined in webapp
window.isRPi = window.isElectron && window.api.isRPi;

window.isWindowsOS = window.isElectron && window.api.isWindowsOS;

window.isLinux = window.isElectron && window.api.isLinux;

// NOTE: process.env is not available in the browser. Webpack automatically replaces
// these variables with "development" or "test" when building the application.

console.log("online " + navigator.onLine);

window.isLocalhost = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
window.isDev = document.domain.endsWith('hazeware.com');

// Generalize the page domain so that iframes work cross-subdomain
if (document.domain !== '127.0.0.1')
    document.domain = document.domain.split('.').slice(-2).join('.')  // reduces app.v2cloud.com to v2cloud.com
