import React from 'react';
import {observer} from 'mobx-react';

import {logged_in} from '../auth';
import openDesktopBrowser from '../openDesktopBrowser.js';

import {active_vm_frame} from '../activeVM.js';

import {wifiController} from '../rpiClient/WifiController';
import {soundController, microphoneController} from '../rpiClient/AudioController';
import {displayController} from '../rpiClient/DisplayController';
import {cameraController} from '../CameraController.js';
import {app_settings, settingsController} from '../SettingsController.js';

import {web_entrypoint, is_whitelabel} from '../consts.js';
import {user_id, user_whitelabel_status, show_chat} from '../userData';
import { printerController } from '../rpiClient/PrinterController';


export default observer(() =>

  <div className="the-topbar" style={{height: '100%',  overflow: 'visible'}}>
     <div className="container-fluid" style={{padding: 0}}>
        <div style={{display: 'flex'}}>
           <div style={{display: 'inline-block'}}>

             {
               // Only show when logged in
               logged_in.get() &&

               // Never show in the share screen
               !window.location.href.includes('/share/') &&

                <div className="no-drag" style={{height: '100%', marginLeft: '20px', display: 'inline-block', verticalAlign: 'top', width: '15px', position: 'relative'}}>
                 <div id="the-menu-trigger" onClick={() => window.$('body').toggleClass('menu-open')}>
                    <span className="line line-1"></span>
                    <span className="line line-2"></span>
                    <span className="line line-3"></span>
                 </div>
                </div>

              }


              <div className="the-title no-drag" style={{visibility: (user_id.get() == null || (is_whitelabel || user_whitelabel_status.get())) ? 'hidden' : 'visible'}}>
                 V2 Cloud
              </div>

              {  user_id.get() != null &&
                  !window.isRPi && // hide open in browser on RPi
                !is_whitelabel && // hide desktop/browser on whitelabel release
                !user_whitelabel_status.get() &&
                web_entrypoint &&
              <div style={{height: '100%', display: 'inline-flex', alignItems: 'center'}}>

                <button type="button" className="btn control-buttons open-desk" onClick={openDesktopBrowser}>
                  <i
                    className={window.isElectron ? "fa fa-window-maximize" : "fa fa-desktop"}
                    aria-hidden="true" style={{verticalAlign: 'middle'}}></i> Open in {window.isElectron ? "Browser" : "Desktop"}
                </button>

              </div>
              }
           </div>

           <div style={{display: 'inline-flex', flexGrow: '1', alignItems: 'center', flexDirection: 'row-reverse'}}>

              <WindowButtons/>
              {( user_id.get() != null && ((!is_whitelabel && !user_whitelabel_status.get()) || active_vm_frame.get())) &&

                <div className="no-drag" id="mobile-toggle-button" onClick={() => window.$('body').toggleClass('list-open')}>
                   <span className="sr-only">Menu</span>
                   <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>

              }
              <ul className="list-horizontal aux-buttons" id="the-menu-items">
                {active_vm_frame.get() && <VMButtons />}
                {user_id.get() != null && (
                  <>
                    {!is_whitelabel && !user_whitelabel_status.get() && (
                      <li>
                        <button
                          type="button"
                          className="btn control-buttons"
                          data-toggle="modal"
                          onClick={() => {
                            const tutorialLink = window.isElectron
                              ? window.api.open_link_external("https://v2cloud.com/tutorials")
                              : "https://v2cloud.com/tutorials";
                            window.open(tutorialLink, '_blank').focus();
                          }}
                        >
                          <i className="fa fa-book" aria-hidden="true"></i> Tutorials
                        </button>
                      </li>
                    )}
                    {show_chat.get() && (
                      <li className="live-help-button blobs-container">
                        <button type="button" className="btn control-buttons live-help-button-v2" id="live-help">
                          <i className="fa fa-user" style={{ color: "#00438b" }} aria-hidden="true"></i> Live help
                        </button>
                      </li>
                    )}
                  </>
                )}
              </ul>
              { window.isElectron && logged_in.get() && <RDPToggle/> }
           </div>
        </div>
     </div>
  </div>

)


const RDPToggle = observer(() =>

  <>
    <button className="btn control-buttons custom-control custom-switch custom-switch-1" style={{paddingLeft: "2rem"}}>
        <input type="checkbox" className="custom-control-input" id="customSwitches1"
          checked={app_settings.get().useRDP}
          onChange={e => settingsController.setOptionByValue('useRDP', e.currentTarget.checked)}/>
        <label className="custom-control-label" htmlFor="customSwitches1">Use RDP</label>
    </button>
  </>

);

const VMButtons = observer(() => (

  <>

     <li>
        <button type="button" className="btn control-buttons make-full-screen-window" onClick={() => {
          window.$('body').toggleClass('full-screen');
      		window.$('body').removeClass('sub-menu-open');
      		window.$('body').removeClass('menu-open')
      		window.$('body').removeClass('list-open');
          window.$('body').trigger("full-screen-change");

          if(window.isElectron) {
            window.api.set_fullscreen(true);
          } else {
            document.body.requestFullscreen();
          }

        }}>
        <i className="fa fa-arrows-alt" aria-hidden="true"></i> Full screen
        </button>
     </li>

     {  (!is_whitelabel && !user_whitelabel_status.get()) && // This modal has branded content
       <li>
          <button type="button" className="btn control-buttons" data-toggle="modal" data-target="#transfer-files">
          <i className="fa fa-cloud-upload" aria-hidden="true"></i> Transfer files
          </button>
       </li>
     }

     <li>
        <button type="button" className="btn control-buttons" data-toggle="modal" data-target="#share-screen-popup">
        <i className="fa fa-share-alt" aria-hidden="true"></i> Share screen
        </button>
     </li>
     
     { window.isElectron &&
     <li>
        <button type="button" className="btn text-left" data-toggle="modal" style={{width: "130px"}} onClick={() => cameraController.controlByContext()}>
          <i className={cameraController.iconStatus}
            style={cameraController.iconStyle}></i>
          <span style={{verticalAlign: 'bottom', marginLeft: '3px'}}>{cameraController.actionStatus}</span>
        </button>
     </li> 
     }
  </>

))

const WindowButtons = observer(() => {

  if(!window.isElectron) return null;

  if(window.isRPi)
    return (
    <>
      <div className="btn-group">
        <button type="button" className="btn" data-toggle="dropdown" style={{background:"#00438b", border: "none"}}>
          <i className="fa fa-power-off" aria-hidden="true" style={{color:'white'}}></i>
        </button>
        <div className="dropdown-menu dropdown-menu-right mt-1">
          <button className="dropdown-item" type="button"
            onClick={() => window.api.reboot()}
            > <i className="fa fa-repeat pr-3" aria-hidden="true" style={{color: "#00438b"}}></i> Reboot</button>
          <button className="dropdown-item" type="button"
            onClick={() => window.api.powerOff()}
            ><i className="fa fa-power-off pr-3" aria-hidden="true" style={{color: "#00438b"}}></i> Shutdown</button>
          <div className="dropdown-divider"></div>
          <button className="dropdown-item" type="button"
            onClick={() => window.api.updateApp()}
            ><i className="fa fa-refresh pr-3" aria-hidden="true" style={{color: "#00438b"}}></i> Check for Update</button>
        </div>
      </div>

      <ul className="list-inline pr-3">
        <li>
          <button type="button" className="btn" data-toggle="modal" data-target="#setup-display" onClick={() => displayController.loadCurrentDisplaySettings()}>
            <i className="fa fa-television" style={{color:"white"}} aria-hidden="true"></i>
            </button>
        </li>
        <li>
          <button type="button" className="btn" data-toggle="modal" data-target="#settings-modal"
            onClick={ () => {
                soundController.scan();
                microphoneController.scan();
                !cameraController.isStreaming && cameraController.scan();
                !printerController.status !== 'scanning' && printerController.scan();
              }
            }
          >
            <i className="fa fa-cog p-0" style={{color:"white"}} aria-hidden="true"></i>
          </button>
        </li>
        <li>
          <button type="button" className="btn" onClick={() => wifiController.scanWifis()} data-toggle="modal" data-target="#setup-wifi">
            <img src={wifiController.iconStatus} alt="" className="top-icon"/>
          </button>
        </li>
      </ul>
    </>
    );

  return (
    <ul className="list-inline">
      <li><button type="button" className="btn" onClick={() => window.api.minimize()}><i className="fa fa-window-minimize" aria-hidden="true"></i></button></li>
      <li><button type="button" className="btn" onClick={() => window.api.toggle_maximize()}><i className="fa fa-window-maximize" aria-hidden="true"></i></button></li>
      <li><button type="button" className="close-button btn" onClick={() => window.api.close()}><i className="fa fa-times" aria-hidden="true"></i></button></li>
    </ul>
  );

})
