import React from 'react';
import {observer} from 'mobx-react';

import {active_perm} from  '../activeVM.js';

import Content from './Content.js';
import FullscreenWrapper from './util/FullscreenWrapper.js';
import {Sidebar} from './sidebar/Sidebar.js';
import {ShowAppButton} from './ShowAppButton.js';


// Hide menu when a vm is selected

window.setTimeout(() => {

  active_perm.get() && window.$('body').removeClass('menu-open');

},2000);



export default observer(() =>

  <FullscreenWrapper>
    <div className={"the-main-row row" + (active_perm.get() ? '' : ' no-overlay')}
      style={{
        position: 'relative',
        //zIndex: 99
      }}>

      <Sidebar/>

      <div className="the-right-section" style={{
        overflow: active_perm.get() ? 'hidden' : 'auto'
      }}>
        <div className="the-inner-right-section">

          <Content/>

        </div>
      </div>

      <UtilDropdown/>

      <ShowAppButton/>

    </div>
  </FullscreenWrapper>

);


const UtilDropdown = () => (

  <ul className="list-group list-group-horizontal">
     <li className="list-group-item"><a href="#" className="make-full-screen-window"><i className="fa fa-arrows-alt " aria-hidden="true"></i> Full Screen</a></li>
     <li className="list-group-item"><a href="#"><i className="fa fa-cloud-upload" aria-hidden="true"></i> Transfer Files</a></li>
     <li className="list-group-item"><a href="#"><i className="fa fa-files-o" aria-hidden="true"></i> Copy/Paste</a></li>
     <li className="list-group-item"><a href="#" data-toggle="modal" data-target="#share-screen-popup"><i className="fa fa-share-alt" aria-hidden="true"></i> Share Screen</a></li>
     <li className="list-group-item"><a href="#"><i className="fa fa-desktop" aria-hidden="true"></i> Open using RDP</a></li>
  </ul>

)
