import {observable, observe} from 'mobx';


export const hash = observable.box(window.location.hash);

window.hash = hash;


window.addEventListener("hashchange", () => {

  hash.set(window.location.hash)

}, false);


observe(hash, () => {


    if(window.location.hash !== hash.get()) {

        // console.log(`hashchange ${window.location.hash} -> ${hash.get()}`);

        window.location.hash = hash.get();

    }

});
