import { action, computed, observable, decorate, transaction } from 'mobx';

// This const must match AUDIO_TYPE in electron/rpiClient/audioExecuter
export const AUDIO_TYPE = {
  CAPTURE: 'Capture',
  PLAYBACK: 'Playback'
};

class AudioController {

  type;
  title;
  
  //@observable
  status = '';
  message = '';

  availableDevices = [];
  
  constructor(type) {
    if(!(type !== AUDIO_TYPE.PLAYBACK || type !== AUDIO_TYPE.CAPTURE))
        throw new Error("Not support AUDIO TYPE");

    this.type = type;
    this.title = type === AUDIO_TYPE.PLAYBACK
                  ? 'Sound Settings'
                  : 'Microphone Settings'
  }

  //@action 
  async scan() {
    try {
      this.status = 'scanning';
      this.message = 'Scanning available devices ...';
        
      this.availableDevices = await window.api.scanAudioDevicesByType(this.type);
      
      // set selected device if not found one
      if(this.availableDevices.length > 0 && !this.availableDevices.find(d => d.isSelected))
        await this.updateSelectedDevice(this.availableDevices[0].id);
      
      this.status = 'done';
      this.message = '';
    } catch (error) {
      this.status = 'failed';
      this.message = 'Failed to scanning devices!';
      console.log(error);
    }
  }

  //@action
  async updateSelectedDevice(id) {
    try {
      const foundDevice = this.availableDevices.find(d => d.id == id);
  
      if(!foundDevice) throw new Error("Not found sound device with id " + id);
      
      // only setAudioDevice for old OS
      const result = window.api.isNewOS || await window.api.setAudioDevice({...foundDevice});
      
      if(result){
        transaction(() => {
          if(this.selectedDevice)
            this.selectedDevice.isSelected = false;
            
          foundDevice.isSelected = true;
        });
      }else
        throw new Error("Failed to set device " + id);
      
    } catch (error) {
      console.log(error);
    }
  }

  //@action
  setVolume(value) {
    if(value < 0 || value > 100) return;
    
    const oldValue = this.selectedDevice.volume;
    this.selectedDevice.volume = value;
    
    window.api.setVolume({...this.selectedDevice}, value)
      .then(result => {if(!result) this.selectedDevice.volume = oldValue; });
  }

  //@computed
  get icon() {// Modal icon
    return this.type === AUDIO_TYPE.PLAYBACK 
            ? 'fa fa-volume-up'
            : this.type === AUDIO_TYPE.CAPTURE
              ? 'fa fa-microphone'
              : ''
  }
  
  //@computed
  get iconStatus() {// TopBar icon
    
    if(this.type === AUDIO_TYPE.PLAYBACK){
      return (!!this.selectedDevice && this.selectedDevice.volume > 0) 
              ? 'fa fa-volume-up'
              : 'fa fa-volume-off';
    }else if (this.type === AUDIO_TYPE.CAPTURE){
      return (!!this.selectedDevice && this.selectedDevice.volume > 0) 
              ? 'fa fa-microphone'
              : 'fa fa-microphone-slash';
    }
  }

  //@computed
  get selectedDevice() {
    return this.availableDevices.find(d => d.isSelected);
  }
  
}

decorate(AudioController, {
  status: observable,
  message: observable,
  availableDevices: observable,
  scan: action.bound,
  updateSelectedDevice: action.bound,
  setVolume: action.bound,
  icon: computed,
  selectedDevice: computed,
});

export const soundController = new AudioController(AUDIO_TYPE.PLAYBACK);
export const microphoneController = new AudioController(AUDIO_TYPE.CAPTURE);
