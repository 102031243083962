import React from 'react';
import { observer } from 'mobx-react';
import { wifiController } from '../../rpiClient/WifiController';

//wifiController.setWifis([
//{
  //ssid: "TOFAM",
  //isConnected: true,
  //securityKey: null
//},
//{
  //ssid: "nvdhau",
  //isConnected: false,
  //securityKey: "9089088"
//}
//]);

export const WifiModal = observer(() => {

  if (wifiController.firstLoad) {
    wifiController.firstLoad = false;
    wifiController.scanWifis();
  }

  return (
    <div className="modal fade" id="setup-wifi" tabIndex="-1" role="dialog" aria-labelledby="setup-wifi" aria-hidden="true">
      <div className="modal-dialog modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title inner-modal-dashboard">
              <i className="fa fa-wifi" aria-hidden="true" style={{ paddingRight: '5px' }}></i> WiFi Setup
              </h5>

            {(wifiController.status != "loading") &&
              <button type="button" className="close" onClick={() => wifiController.scanWifis()}>
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </button>
            }

            <button type="button" className="close ml-0" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body p-3">
            <div className="the-content-inner text-dark">
              <div className="row ">
                <div className="col-md-12">

                  <ul className="list-group list-group-flush">
                    {wifiController.status == "loading"
                      ?
                      <p className="h5">
                        <span className="spinner-border mr-3" role="status" aria-hidden="true"></span> {wifiController.message} </p>
                      : wifiController.wifis.length === 0
                        ? <p className="h5 text-danger"> No WiFi access point found.<br />Please retry or reset the computer! </p>
                        : wifiController.wifis.map((wifi) => {
                          wifi.showPassword = false;
                          return <WiFiItem key={wifi.ssid} wifi={wifi} />
                        })
                    }
                  </ul>
                </div>
              </div>
              
              { (window.api.isNewOS && wifiController.status != "loading") &&
              <div className="row">
                <div className="col-md-8 offset-md-4">
                  <button type="button" className="btn btn-blue btn-block pl-2"  onClick={() => window.api.openNetworkManager()}>
                    Advanced Network Settings
                  </button>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export const WiFiItem = observer(({ wifi }) => {
  const { id, ssid, isConnected, securityKey, showPassword } = wifi;

  return (
    <li key={id} className={"list-group-item p-1 " + (isConnected ? "d-flex justify-content-between" : "")}>
      <div>
        <button className="btn pl-0" type="button" data-toggle="collapse" data-target={`#${id}`} aria-expanded="false" aria-controls={id}>
          {ssid}
        </button>
        {!isConnected &&
          <div className="collapse" id={id}>
            <div className="form-group row">
              <div className="col-sm-8 input-group">
                <input type={showPassword ? "text" : "password"} className="form-control border-secondary" placeholder="Enter password"
                  value={securityKey}
                  onChange={v => wifi.securityKey = v.target.value}
                  required
                />
                <div className="input-group-append">
                  <button type="button" className="btn border-secondary" style={{ width: "50px" }}
                    onClick={() => wifi.showPassword = !showPassword}>
                    <i id="passwordIcon" className={showPassword ? "fa fa-eye-slash fa-lg" : "fa fa-eye"} style={{ color: "#00438b" }} aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="col-sm-4">
                <button type="button" className="btn btn-success btn-block p-2" onClick={() => wifiController.connectWifi(ssid)}>Connect</button>
              </div>
            </div>
          </div>
        }
      </div>

      { // display connected status and disconnect button
        isConnected &&
        <div>
          <span className="text-success" >Connected</span>
          {/* <button type="button" className="btn p-2">
          <i className="fa fa-times fa-lg" aria-hidden="true" style={{ color: 'red', paddingBottom: '5px' }} ></i>
        </button> */}
        </div>
      }
    </li>
  )
});
