import React from 'react';

import {observable} from 'mobx';


// This component forewards all its props to an underlying component (it is a higher-order component).

// It remembers the props history and keeps past versions of the underlying component
// appended to the DOM with "display: none".

// This way we gain the ability to switch between DOM nodes without unloading them.


// The "key" prop in the div is important. It's what React uses to distinguish and cache the elements.

export default Component => {

    const cache = observable.map({}, { deep: false });


    return {

      cache,

      component: props => {


        props.cache_key && props[props.cache_key] && !cache.has(props[props.cache_key]) &&

          cache.set(props[props.cache_key], {

            key: Math.random(),
            ...props

          });


        return (
            <>
            {
                [...cache].map(([_, _props]) =>

                    <div
                        key={_props.key}
                        style={{
                            display: _props[_props.cache_key] === props[props.cache_key] ? 'block' : 'none',
                            width: '100%',
                            height: '100%'
                        }}>
                        <Component {..._props}/>
                    </div>
                )
            }
            </>
        );

      }

    };

}
