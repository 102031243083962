import {logged_in} from './auth.js';
import {autorun} from 'mobx';
import {active_perm} from './activeVM.js';
import {permission_list} from './userData.js';



window.intercomSettings = {
  custom_launcher_selector: '.live-help-button',
  hide_default_launcher: true
};


// Intercom included in at the bottom of index.html

setTimeout(() => window.$('.live-help-button').click(() => {

  if(!logged_in.get()) {
    window.intercomSettings.app_id = "dpnt16mf";
    window.Intercom('boot', window.intercomSettings);
  }
}), 0);



export const log_in = intercom_api_result => {
  const o = {
    ...window.intercomSettings,

    arr: '$' + intercom_api_result.arr,
    mrr: '$' + intercom_api_result.mrr,
    effective_arr: '$' + intercom_api_result.effective_arr,
    effective_mrr: '$' + intercom_api_result.effective_mrr,

    active_vms: intercom_api_result.active_vms,
    app_id: intercom_api_result.app_id,
    app_version: intercom_api_result.app_version,
    basic_vms: intercom_api_result.basic_vms,
    business_vms: intercom_api_result.business_vms,

    created_at: Number(intercom_api_result.created_at),

    email: intercom_api_result.email,
    show_chat: intercom_api_result.show_chat,

    is_app_user: intercom_api_result.is_app_user,
    is_managed: intercom_api_result.is_managed,

    last_active_vm: Number(intercom_api_result.last_active_vm_date),
    name: intercom_api_result.name,
    pending_vms: intercom_api_result.pending_vms,
    referral_url: intercom_api_result.referral_url,
    trial_end: intercom_api_result.trial_end,
    user_hash: intercom_api_result.user_hash,

    last_seen_at: Math.floor(Date.now() / 1000)
  };

  window.intercomSettings = o;
  window.Intercom('boot', window.intercomSettings);
};


export const log_out = () => {

  window.intercomSettings = {
    custom_launcher_selector: '.live-help-button',
    hide_default_launcher: true
  };

  window.Intercom('shutdown');

};
