import React from 'react';
import {observer} from 'mobx-react';
import {active_perm, active_vm_frame} from  '../activeVM.js';
import {permission_list} from '../userData.js';



const click = () => {

  active_vm_frame.get().inject_alt_tab();

};


export const ShowAppButton = observer(class extends React.Component {

  componentDidMount() {

    const $el = window.$('#show-app-button');

    $el.tooltip();

    $el.mouseover(() => {
      window.setTimeout(() => $el.tooltip('hide'), 2000);
    });

  }

  render() {

    const pl = permission_list.get();
    const ap = active_perm.get();

    return (
      <div style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        visibility: (pl[ap] && (pl[ap].type === 'app' || pl[ap].type === 'vmpool-app')) ? 'visible' : 'hidden'
      }}>
        <button onClick={click} title="Maximize Application" id='show-app-button'
          style={{
            background: '#00438b',
            color: 'white',
            border: 'none',
            borderRadius: '50px',
            width: '35px',
            height: '35px',
            margin: 0
          }}>
          <i className="fa fa-window-maximize" style={{
            color: 'white',
            fontSize: '1.3em'
          }}></i>
        </button>
      </div>
    );

  }

});
