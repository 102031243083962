import React from 'react';
import {observer} from 'mobx-react';
import {autorun} from 'mobx';

import {logged_in, WithAuth} from '../auth.js';

import {hash} from '../url.js';

import '../autoLogin.js';

import {Modals} from './Modals.js';
import {WithLoadingScreen} from './LoadingScreen';
import TopBar from './TopBar.js';
import Login from './Login.js';
import Dashboard from './Dashboard.js';
import Share from './Share.js';


export const App = () =>

  <>

    <TopBar/>

    <WithLoadingScreen>
      <Body/>
    </WithLoadingScreen>

    <Modals/>

  </>



autorun(() => {

  // redirect to home if path not known

  !hash.get().startsWith('#/share') &&
  !hash.get().startsWith('#/login') &&
  !hash.get().startsWith('#/') &&

    hash.set('#/');


  // redirect to a fixed path for certain subdirectories

  hash.get().startsWith('#/login') && hash.set('#/login');


  // redirect based on log-in state

  // Note: logged_in begins as undefined. true and false are set to the observer after the result of an initial API request.

  (logged_in.get() === false) &&

    hash.set('#/login');


  (hash.get().startsWith('#/login')) &&
  (logged_in.get() === true) &&

    hash.set('#/');

});


export const Body = observer(() =>

  <>

    { hash.get().startsWith('#/share') && <Share/> }

    { hash.get().startsWith('#/login') && <Login/> }

    { hash.get().startsWith('#/') &&
     !hash.get().startsWith('#/share') &&
     !hash.get().startsWith('#/login') && <Dashboard/> }

  </>

)
