import {active_vm_frame} from '../activeVM.js';
import {autorun} from 'mobx';



// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};



// Only run f once the resize event after 200ms of resize inactivity

const bind_resize = f => {

  const f2 = debounce(f, 400);

  const h = () => setTimeout(f, 1000);

  window.$('body').on('full-screen-change', f2);
  window.addEventListener('resize', f2);
  window.addEventListener('fullscreenchange', h);

};


const inject_resize = () => {

  const iframe = active_vm_frame.get();

  if(!iframe) return;


  try {

    // Override guacamole default resize mechanics

    const width = iframe.clientWidth * window.devicePixelRatio;
    const height = iframe.clientHeight * window.devicePixelRatio;



    console.log(`resize to ${width}px x ${height}px`);


    // Nor does fitVisibleArea. The $window.setInterval in this file seems to fail.

    // https://github.com/apache/guacamole-client/blob/0091bb1aea14c567c8166f0ed8eadf7c31b6bd6e/guacamole/src/main/webapp/app/client/directives/guacViewport.js#L67
    // guacamole-client/guacamole/src/main/webapp/app/client/directives/guacViewport.js#L67

    const viewport = iframe.contentWindow.angular.element('.viewport')[0];

    viewport.style.width = iframe.clientWidth + 'px';
    viewport.style.height = iframe.clientHeight + 'px';


    // mainElementResized doesn't fire correctly

    // https://github.com/apache/guacamole-client/blob/0091bb1aea14c567c8166f0ed8eadf7c31b6bd6e/guacamole/src/main/webapp/app/client/directives/guacClient.js#L391
    // guacamole-client/guacamole/src/main/webapp/app/client/directives/guacClient.js#L391

    iframe.contentWindow.angular.element('.display').scope().client.client.sendSize(width, height);


  } catch(e) {

    console.log(e);

  }

};


bind_resize(inject_resize);


// also run on iframe change
window.setTimeout(() => autorun(() => {

  active_vm_frame.get();

  window.setTimeout(inject_resize, 400);

}), 0);
