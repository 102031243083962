import React from 'react';
import {observer} from 'mobx-react';

import {is_loading} from '../loading.js';


export const LoadingScreen = () =>

  <div className="the-login-section">
    <div className="form-wrap">
      <div className="loading-sec">
        <div className="loading-icon">
          <i className="fa fa-spinner fa-pulse"></i>
        </div>
        <p>Loading...</p>
      </div>
    </div>
  </div>


export const WithLoadingScreen = observer(({children}) =>

    is_loading.get() ? <LoadingScreen/> : children)
