import {active_vm_frame} from '../activeVM.js';
import {autorun} from 'mobx';


window.setTimeout(() => autorun(() => {

  bind_alt_tab(active_vm_frame.get());

}), 0);



const bind_alt_tab = iframe => {

  if(!iframe) return;



  function triggerMouseEvent (node, eventType) {
      const clickEvent = iframe.contentDocument.createEvent('MouseEvents');
      clickEvent.initEvent(eventType, true, true);
      node.dispatchEvent(clickEvent);
  }


  iframe.contentWindow.$('<style>.keyboard-wrapper { visibility: hidden; } .keyboard-container { pointer-events: none; }</style>').appendTo('head');



  const wait = ms => new Promise(res => window.setTimeout(res, ms));

  const delay = 50


  iframe.inject_alt_tab = async () => {


    if(!iframe.contentWindow.$(".guac-keyboard-key-lalt")[0]) {

      iframe.contentWindow.$("#task-bar-button-keyboard").click();

      await wait(500);

    }


    triggerMouseEvent(iframe.contentWindow.$(".guac-keyboard-key-lalt")[0], 'mousedown');

    await wait(delay);

    triggerMouseEvent(iframe.contentWindow.$(".guac-keyboard-key-lalt")[0], 'mouseup');

    await wait(delay);

    triggerMouseEvent(iframe.contentWindow.$(".guac-keyboard-key-tab")[0], 'mousedown');

    await wait(delay);

    triggerMouseEvent(iframe.contentWindow.$(".guac-keyboard-key-tab")[0], 'mouseup');

    // await wait(delay);
    // 
    // triggerMouseEvent(iframe.contentWindow.$(".guac-keyboard-key-lalt")[0], 'mousedown');
    //
    // await wait(delay);
    //
    // triggerMouseEvent(iframe.contentWindow.$(".guac-keyboard-key-lalt")[0], 'mouseup');

  }


};
