import React from 'react';
import { RPiSettings } from './rpiClient/RPiSettings';
import { WindowsSettings } from './WindowsSettings';

export const SettingsModal = () => (

  <div className="modal fade" id="settings-modal" tabIndex="-1" role="dialog" aria-labelledby="settings-modal" aria-hidden="true">
     <div className="modal-dialog modal-dialog modal-md" role="document">
        <div className="modal-content">
           <div className="modal-header">
              <h5 className="modal-title inner-modal-dashboard">
                 <i className="fa  fa-cog" aria-hidden="true" style={{paddingRight: '5px'}}></i>Settings
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
           </div>
           <div className="modal-body p-3">
             { window.isRPi  ?  <RPiSettings/> : <WindowsSettings/>
             }
           </div>
        </div>
     </div>
  </div>

);
