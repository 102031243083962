import { active_perm } from '../../activeVM.js';
import { vm_cache } from './WebconnectFrame.js';
import { toast } from '../Toast.js'

export const listen_iframe_close = (iframe, perm_id) => {
  const listener = window.setInterval(() => {

    // This only works when the iframe is same-origin. If the connection fails to initialize the guacamole extension,
    // we cannot recognize disconnections.

    if (iframe.contentWindow) {
      const $ = iframe.contentWindow.$;
      const guacamoleLoginPage = $('.login-ui:not(.ng-hide)').length;
      const disconnectedDialog = $('.title:contains("Disconnected")').length;
      const errorDialog = $('.error').length;

      if (guacamoleLoginPage || disconnectedDialog || errorDialog) {
        const errorElement = $('.error')?.[0];

        if (errorElement) {
          const errorMessage = (
              errorElement.getElementsByClassName("error-text")?.[0]?.innerText ||
              errorElement.getElementsByClassName("text")?.[0]?.innerText
          );

          if (errorMessage) {
            toast.error({ text: errorMessage, hideAfter: 7000 });
            clearInterval(listener);
          }
        }

        // if selected
        if (active_perm.get() === perm_id) {
          active_perm.set();
        }
        vm_cache.delete(perm_id);
      }
    }
  }, 250);
};

