import axios from 'axios';
import {hash} from './url.js';
import {logged_in, oauth_endpoint} from './auth.js';
import {web_entrypoint} from './consts.js';
import {getItem, setItem} from './storage.js';


// Generates a protocol link with the token in it. See electron/customProtocol.js.

export const desktop_link = async () => "v2://" + window.location.hash;


// Generates a url link with a token in it

export const browser_link = async () => web_entrypoint + window.location.hash;
