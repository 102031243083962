import React from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

import { vms_list, permission_list, user_id, vms_list_original } from "../userData.js";

import { freeRDPController } from "../rpiClient/FreeRDPController";
import { settingsController } from "../SettingsController";
import { open_vm } from "../useRDP.js";

import { LoadingScreen } from "./LoadingScreen";
import { VMStatus } from './VMStatus';

const logged_in_user_id = user_id;

const loading = observable.box(false);

const show_loading_screen =
  (f) =>
  async (...args) => {
    loading.set(true);

    try {
      await f(...args);
    } catch (e) {
      console.error(e);
    }

    loading.set(false);
  };


const splashPermissionsForVM = (id) =>

  Object.entries(permission_list.get())

    .filter(([_, { vm }]) => vm.id === id)

    .filter(([_, { user }]) => user.id === user_id.get());


function capitalizeFirstLetter(string) {
  if(string === undefined) {
    return ""
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}



export default observer(
  class extends React.Component {
    componentDidMount() {
      window.$(".splash").slimscroll({
        height: "calc(100vh - 40px)",
        width: "100%",
      });
    }

    render() {
      return (
        <div>
          <div className="splash">
            <div className="the-inner-right-section">
              <div id="front-screen">
                <h1 className="welcome-page-title add-cloud-computer-message building-process-message">
                  Get Started!
                </h1>
                <p className="welcome-message building-process-message tutorials-page">
                  Click below to get connected
                </p>

                {loading.get() ? (
                  <LoadingScreen />
                ) : (
                  Object.values(vms_list.get()).map(
                    ({ descriptor: vm_description, id, is_vmpool, state }) =>
                      // Only display VM category if there exists a permission

                      !splashPermissionsForVM(id).length ? null : (
                        <div key={id} className="container main-page-display">
                          <>
                            <div className="main-wraper-vm-name">
                              <div className="vm-name-new">
                                <span className="vm-name">
                                  <i
                                    className={
                                      is_vmpool ? "fa fa-sitemap" : "fa fa-desktop"
                                    }
                                    aria-hidden="true"
                                    style={{padding: 0}}
                                  ></i>
                                  <span style={{marginLeft: 10}}>
                                    {vm_description}
                                  </span>
                                  { vms_list_original.get().length !== 0 &&
                                    <div style={{marginTop: '-5px'}}>
                                      <VMStatus state={state} id={id} style={{marginLeft: '5px', marginRight: '5px', fontSize: '13px',}}/>

                                        <span style={{fontSize: '13px', color: '#888888'}}>
                                          {capitalizeFirstLetter(state || (vms_list_original.get().find(({id: id_}) => id_ == id) && vms_list_original.get().find(({id: id_}) => id_ == id).state))}
                                        </span>

                                    </div>
                                  }
                                </span>
                              </div>
                            </div>
                            <div className="row text-center">
                              {splashPermissionsForVM(id).map(
                                ([perm_id, { vm, vmuser, type, app }]) => (
                                  <div
                                    className="col-lg-4 col-md-6"
                                    key={perm_id}
                                  >
                                    <a
                                      onClick={() => open_vm(perm_id)}
                                      style={{ cursor: "pointer" }}
                                      className="trigger-sidebar"
                                    >
                                      <div className="card main-page-app">
                                        <div className="card-body card-body-custom">
                                          <div className="image-container-main-page">
                                            <img
                                              src={
                                                type === 'vm' ? "images/desktop-application-icon-v2cloud2.png" : (
                                                  type === 'vmpool' ? "images/desktop-application-icon-v2cloud2-pool.png" : (
                                                    type === 'app' ? "images/computer2.png" : "images/computer2-pool.png"
                                                  )
                                                )
                                              }
                                              style={{width: 66}}
                                            />
                                          </div>
                                          <div className="col pc-menus-triggers">
                                            <div className="col pc-menus-triggers">
                                              <span className="pc-name">
                                                {(type === "vm" || type == 'vmpool') ? "Desktop" : ""}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="pc-name">
                                            {(type === "vm" || type === "vmpool")
                                              ? vms_list.get()[vm.id].descriptor
                                              : app.display_name}
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                )
                              )}
                            </div>
                          </>
                        </div>
                      )
                  )
                )}

                {
                //   !loading.get() && permission_list.get().filter(perm => ).map()
                }

              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);
