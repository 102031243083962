import React from 'react';
import {open_rdp} from '../RDPModal.js';
import openDesktopBrowser from '../../openDesktopBrowser.js';
import {is_whitelabel} from '../../consts.js';
import {user_whitelabel_status, user_id} from "../../userData";


const is_fullscreen = () =>
  document.fullscreenElement ||
  document.webkitFullscreenElement ||
  document.mozFullScreenElement;

const exit_fullscreen = () => {

  window.$('body').removeClass('full-screen');
  window.$('body').removeClass('sub-menu-open');
  window.$('body').removeClass('menu-open')
  window.$('body').removeClass('list-open');
  window.$('body').trigger("full-screen-change");


  if(window.isElectron) {
    is_fullscreen() && window.api.set_fullscreen(false);
  } else {
    is_fullscreen() && document.exitFullscreen();
  }


};


window.addEventListener('fullscreenchange', () => !is_fullscreen() && exit_fullscreen());



export default class extends React.Component {

  componentDidMount() {

    const i = document.getElementById("mydiv");

    // enable tooltips
    window.$(i).find('button').tooltip();

    dragElement(i);



    function dragElement(elmnt) {


      const trigger = window.$(elmnt).find('.the-full-screen-trigger-menu');

      // click regisers only if not dragged
      trigger[0].onmouseup = () => {

        // if cursor as moved more than this number of pixels, the click is a drag
        const threshold = 8;

        if(Math.sqrt(Math.pow(start_x - pos3, 2) + Math.pow(start_y - pos4, 2)) < threshold) {

          window.$('body').toggleClass('sub-menu-open')

        }

      };

      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      let start_x, start_y;
      function dragMouseDown(e) {

        start_x = e.clientX;
        start_y = e.clientY;

        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;

        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {

        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }


  }

  render() {

    return (
      <div className="the-main-content">
    		<div className="container-fluid">
          <div className="full-screen-wrapper-container">
             <div id="mydiv">
                <div id="mydivheader">
                   <div className="the-full-screen-trigger-menu" title="Draggable menu">
                      <span>
                        {(user_whitelabel_status.get() || is_whitelabel) ?
                            <span style={{
                              fontWeight: 'bold',
                              fontSize: '1.5em',
                              display: 'inline-block',
                              marginTop: '2px'
                            }}>
                              ?
                            </span>

                          :
                            <img src="images/menu.png" alt=""/>
                        }
                      </span>
                   </div>
                </div>
                <ul>
                   <li>
                      <button type="button" className="btn control-buttons make-full-screen-window" title="Exit Full screen" onClick={exit_fullscreen}>
                      <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                      </button>
                   </li>
                   { !window.isRPi && !user_whitelabel_status.get() && !is_whitelabel &&
                   <li>
                      <button type="button" className="btn control-buttons" title={window.isElectron ? "Open in Browser" : "Open in Desktop"} onClick={openDesktopBrowser}>
                      <i style={{color: '#00438b', padding: 0}} className={window.isElectron ? "fa fa-window-maximize" : "fa fa-desktop"} aria-hidden="true"></i>
                      </button>
                   </li>
                   }

                   { !is_whitelabel && !user_whitelabel_status.get() &&

                     <li>
                        <button type="button" className="btn control-buttons" data-toggle="modal" data-target="#transfer-files" title="Transfer files">
                        <i className="fa fa-cloud-upload" aria-hidden="true"></i>
                        </button>
                     </li>

                   }

                   <li>
                      <button type="button" className="btn control-buttons" data-toggle="modal" data-target="#share-screen-popup" title="Share screen">
                      <i className="fa fa-share-alt" aria-hidden="true"></i>
                      </button>
                   </li>

                   { !is_whitelabel && !user_whitelabel_status.get() &&
                     <>
                   <li>
                      <button type="button" className="btn control-buttons" title="Tutorials" data-toggle="modal" onClick={() => {
                        window.isElectron ? window.api.open_link_external("https://v2cloud.com/tutorials") : window.open("https://v2cloud.com/tutorials", '_blank').focus()
                      }}>
                      <i className="fa fa-book" aria-hidden="true"></i>
                      </button>
                   </li>

                   <li>
                      <button type="button" className="btn control-buttons" title="Live Help" onClick={() => {
                        // since this isn't mounted on intercom initialization, we click the other live help button which already
                        // has the proper observer
                        window.$('.live-help-button').click();
                      }}>
                      <i className="fa fa-user" style={{color:"#00438b"}} aria-hidden="true"></i>
                      </button>
                   </li>
                   </>

                 }
                </ul>
             </div>
          </div>

          {this.props.children}

        </div>
      </div>
    )

  }

}
