const $ = window.$;
export class Toast {

    #prepare_toast_options = (options) => {
        let _options = {};
        if ((typeof options === 'string') || (options instanceof Array)) {
            _options.text = options;
        } else {
            _options = options;
        }
        return _options;
    }

    init() {
        let default_opts = $.extend({}, $.toast.options, {position: 'bottom-right', hideAfter: 2000,})
        this.success = (options) => {
            $.toast($.extend({}, default_opts, {heading: 'Success'}, this.#prepare_toast_options(options), {icon: 'success'}))
        }
        this.info = (options) => {
            $.toast($.extend({}, default_opts, {heading: 'Info'}, this.#prepare_toast_options(options), {icon: 'info'}))
        }
        this.warning = (options) => {
            $.toast($.extend({}, default_opts, {heading: 'Warning'}, this.#prepare_toast_options(options), {icon: 'warning'}))
        }
        this.error = (options) => {
            $.toast($.extend({}, default_opts, {heading: 'Error'}, this.#prepare_toast_options(options), {icon: 'error'}))
        }
        this.successfullyRequested = (msg) => {
            this.success(msg || 'Request as been successfully sent');
        }

    }
}

const toast = new Toast()
toast.init()

export {toast};