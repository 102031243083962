import axios from 'axios';


export const poll_task = async task_id => {

  const start = new Date().getTime();
  let completed = false;

  while(!completed) {

    if((new Date().getTime() - start) > 60000) {
      throw new Error('OS user creation task took longer than one minute')
    }

    const data = (await axios({
      url: `/api/tasks/${task_id}`,
    })).data;

    if(data.failed) {
      throw new Error('OS user creation task failed')
    }

    completed = (data.status == 'done');

    await new Promise(resolve => setTimeout(resolve, 5000));

  }

};
