import React from 'react';
import { observer } from 'mobx-react';
import { app_settings }  from '../SettingsController';


function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

function range(size, startAt = 0) {
  return [...Array(size).keys()].map(i => i + startAt);
}

const toggle_element = (list, x) => {

  if(list.includes(x)) {
    return removeItemOnce(list, x)
  } else if(list.length === 0) {
    return [x]
  } else {

    let max = Math.max(...list);
    let min = Math.min(...list);

    if(x > max) {
      return [...list, ...range(x-max, max+1)]
    } else {
      return [...list, ...range(min-x, x)]
    }

  }

}

const monitor_id = (monitor, i) => {

  try {
    return Number(monitor.DeviceName.split('DISPLAY')[1].split('\\')[0]) - 1
  } catch(e) {
    console.log(e)
    return i
  }

}

const is_inactive = (selected_monitors, i) => {
  return selected_monitors.includes(i-1) && selected_monitors.includes(i+1)
}


export const MultimonSelect = observer(class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      monitors: undefined,
      loading: true
    }
  }

  async componentDidMount() {

    this.setState({loading: true})

    const monitors = await window.api.getWindowsMonitors()

    const settings = app_settings.get()


    const all_monitors = monitors.map(monitor_id)

    if(!settings.multimonSelected ||
       !settings.multimonSelected.length ||
        settings.multimonSelected.some(x => !all_monitors.includes(x))) {

      app_settings.set({
        ...settings,
        multimonSelected: all_monitors
      })
    }

    this.setState({
      monitors,
      loading: false
    })

  }

  render() {

    if(this.state.loading) {

      return (
        <div style={{textAlign: 'center'}}>
          <i className="fa fa-spinner fa-pulse"></i>
        </div>
      )

    }

    if(!this.state.monitors || !this.state.monitors.length) return null;


    const settings = app_settings.get()

    return (
      <>
        <div style={{
          marginBottom: '15px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          rowGap: '5px'
        }}>
          {
          (this.state.monitors.map((monitor, i) =>
            <div
              key={i}
              className={"monitorSelection " + (settings.multimonSelected.includes(monitor_id(monitor, i)) ? 'selected' : '')}
              style={{
                ...(is_inactive(settings.multimonSelected, i) && {cursor: 'not-allowed'})
              }}
              onClick={() => {
                if(!is_inactive(settings.multimonSelected, i)) {
                  app_settings.set({
                    ...settings,
                    multimonSelected: toggle_element(settings.multimonSelected, monitor_id(monitor, i))
                  })
                }
              }}
              title={is_inactive(settings.multimonSelected, i) ? "Selection must be contiguous with no gaps" : ""}
              >
              Display {i+1}<br/>
              {monitor.Bounds.Width}x{monitor.Bounds.Height}
            </div>
          ))
          }
        </div>
      </>
    )

  }

})
